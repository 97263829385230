.Warnings {
    $root: &;

    display: none;

    @at-root .no-js & {
        display: block;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
    }

    &__warning {
        display: none;
        padding: vr(1.75);
        border-radius: vr(0.5);
        margin-top: vr(1.75);

        &--nojs {
            background-color: $color-javascript;
            color: $color-brand-white;

            @at-root .no-js & {
                display: block;

                + #{$root}__warning--ie {

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        margin-top: vr(1);
                    }
                }
            }
        }

        &--ie {
            background-color: $color-msedge;
            color: $color-brand-white;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                display: block;
            }
        }
    }

    &__title {
        display: block;
        font-size: rem(16);
        line-height: vr(1.75);
        font-weight: $font-weight-medium;
    }

    &__help {
        display: block;
        margin-top: vr(0.25);
        font-size: rem(14);
        line-height: vr(1.5);
        font-weight: $font-weight-normal;

        > a {
            text-decoration: underline;
            color: inherit;

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }
}
