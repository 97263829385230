.ElementCTA {
    $root: &;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: vr(2.5);
    align-items: center;

    @include mq-from(mainblock-bp2) {
        grid-template-columns: 2fr 1fr;
    }

    &__cta {

        @include mq-from(mainblock-bp2) {
            text-align: center;
        }
    }
}
