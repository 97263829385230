$mq-breakpoints: (
    site-bp0: 440px,
    site-bp1: 530px,
    site-bp2: 768px,
    site-bp3: 850px,
    site-bp4: 1024px,
    site-bp5: 1150px,

    stats-bp1: 414px,
    stats-bp2: 530px,
    stats-bp3: 768px,
    stats-bp4: 1024px,
    stats-bp5: 1120px,

    cmscontent-bp1: 400px,
    cmscontent-bp2: 540px,
    cmscontent-table-bp1: 600px,
    cmscontent-inlineimg-bp1: 475px,

    masthead-bp0: 414px,
    masthead-bp1: 580px,
    masthead-bp2: 1020px,
    masthead-bp3: 1090px,
    masthead-bp4: 1220px,

    mainmenu-bp1: 1090px,
    mainmenu-bp2: 1220px,

    dock-bp1: 375px,
    dock-bp2: 480px,
    dock-bp3: 768px,

    grid-bp1: 400px,
    grid-bp2: 768px,
    grid-bp3: 1024px,
    grid-bp4: 1280px,

    block-bp1: 768px,
    block-bp2: 1024px,
    block-bp3: 1400px,

    forms-bp1: 568px,
    userforms-controls-bp1: 640px,
    wizard-nav-bp1: 600px,
    blockquote-bp1: 475px,
    table-bp1: 475px,

    ///////////////////////

    footer-bp0: 550px,
    //footer-bp1: 11700px,
    footer-bp1: 768px,
    footer-bp3: 1024px,
    footer-bp4: 1240px,

    container-bp1: 568px,
    container-bp2: 820px,

    hero-bp1: 520px,
    hero-bp2: 768px,
    hero-bp3: 1024px,
    hero-bp4: 1150px,
    hero-bp5: 1310px,
    hero-bp6: 1500px,


    crumbsbar-bp1: 840px,

    buttons-bp1: 614px,

    pagination-bp1: 440px,
    modal-bp1: 768px,

    listcontrols-bp0: 470px,
    listcontrols-bp1: 568px,
    listcontrols-bp2: 768px,
    listcontrols-bp3: 800px,
    listcontrols-bp4: 1024px,

    el-feature-bp1: 768px,
    el-iconicslist-bp1: 768px,

    mainblock-bp1: 768px,
    mainblock-bp2: 820px,
);

$z-index: (
    site-body: 1,
    site-masthead: 120,
    site-dock: 120,
    site-photoswipe: 300,
    site-skiplinks: 1000,

    steps-bar: 80,
    steps-step: 81,

    masthead-mainmenu: 50,
    masthead-megamenu: 60,
    menu-megamenu: 60,

    hero: 45,
    hero-overlay: 46,
    hero-container: 47,
    hero-content: 48,
    hero-metabar: 48,

    hero-background: 45,
    hero-background-overlay: 50,
    hero-body: 55,


    site-modal: 500,

    block-background: 30,
    block-background-overlay: 32,
    block-container: 35,
    block-body: 35
);

$site-container: (
    max-width: 1240px,
    gutters: 18px 30px 42px
);
$site-gutters: map-get($site-container, gutters);

$em-base: 16px;
$vr-line-height: 1.5;
$vr-em-base: 16px;
$base-font-size: 100%;
$base-line-height: 1.5;

$assets-path-base-dir: '/_resources/themes/ppiu/dist/';
$fonts-path: $assets-path-base-dir + 'fonts/';

$content-max-width: 800px;

$font-family-headings: 'DM Sans', Helvetica, Tahoma, Verdana, Arial, "Lucida Sans", sans-serif;
$font-family-headings-sec: 'Satoshi', Helvetica, Tahoma, Verdana, Arial, "Lucida Sans", sans-serif;
$font-family-body: 'Satoshi', Helvetica, Tahoma, Verdana, Arial, "Lucida Sans", sans-serif;

$color-brand-black: $color-black;
$color-brand-black-off: $color-tuatara;
$color-brand-white: $color-white;
$color-brand-grey-lighter: #EDEDED;
$color-brand-grey-light: #DDD;
$color-brand-grey: #737373;
$color-brand-grey-dark: #515151;
$color-brand-palegreen-light: #ECF5EB;
$color-brand-palegreen: #DCECD9;
$color-brand-palegreen-dark: #BFDDB8;
$color-brand-green: #6FB055;
$color-brand-green-hover: #5EA041;
$color-brand-green-focus: #4D9030;

$color-brand-blue-true: #005D9A;
$color-brand-blue-harmony: #e1eef7;
$color-brand-blue-empower: #1A80CE;
$color-brand-blue-expert: #002F4D;
$color-brand-blue-pale: #bedbff;
$color-brand-grey-calm: #606060;
$color-brand-grey-strong: #747C86;
//$color-brand-grey: #7f8494;
$color-brand-grey-light: #f7f7f7;
$color-brand-yellow-action: #FFCC07;
$color-brand-yellow-light: #ffeca5;

$color-brand-green: #00c76b;
$color-brand-green-dark: #00944F;
$color-brand-black: #000000;
$color-brand-nero: #111111;
$color-brand-charcoal: #555555;
$color-brand-grey: #747578;
$color-brand-dull: #BBBBBB;
$color-brand-gainsboro: #DDDDDD;
$color-brand-smoke: #EEEEEE;
$color-brand-silver: #EFEFEF;
$color-brand-ghost: #FAFAFA;
$color-brand-white: #FFFFFF;
$color-brand-grey-calm: #606060;

$color-brand-success: #379927;
$color-brand-info: #0085AE;
$color-brand-danger: #DC7E93;
$color-brand-warning: #DFA72D;


$color-brand-pacificblue-pale: #5D7F99;
$color-brand-pacificblue-light: #385F7D;
$color-brand-pacificblue: #1F4765;
$color-brand-pacificblue-dark: #0C2F4A;

$color-brand-warmorange-pale: #FFBD82;
$color-brand-warmorange-light: #FFA758;
$color-brand-warmorange: #FF9333;
$color-brand-warmorange-dark: #E77107;

$color-brand-palmgreen-pale: #91CA91;
$color-brand-palmgreen-light: #5EA85E;
$color-brand-palmgreen: #388A39;
$color-brand-palmgreen-dark: #1F701F;

$color-brand-heritagered-pale: #FFC5B9;
$color-brand-heritagered-light: #E79482;
$color-brand-heritagered: #BD604C;
$color-brand-heritagered-dark: #943723;

$color-brand-conchshell-pale: #F4EFEC;
$color-brand-conchshell-light: #FFF8F3;
$color-brand-conchshell: #EEDED2;
$color-brand-conchshell-dark: #CBB2A0;

$color-brand-lightgrey-pale: #F9F9F8;
$color-brand-lightgrey-light: #EDECED;
$color-brand-lightgrey: #C9C9CA;
$color-brand-lightgrey-dark: #C7C5C5;

//#fdfbfa
//#babab9

$color-brand-darkgrey-pale: #9B9A9A;
$color-brand-darkgrey-light: #686868;
$color-brand-darkgrey: #303030;
$color-brand-darkgrey-dark: #231f20;

$color-body-background: $color-brand-white;
$color-body-text-color: $color-brand-darkgrey;
