.SherlockForm {
    $root: &;

    width: 100%;

    &__form {

    }

    &__label {
        @include visually-hidden;
    }

    &__field {
        display: flex;
        flex-direction: row;
        position: relative;
    }

    &__input {
        flex-grow: 1;
        position: relative;
        z-index: 1;

        &[type=search] {
            width: vr(24);
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__action {
        flex-shrink: 0;
        align-self: flex-end;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:not([disabled]):not(.is-loading):not(.Button--text):active {
            top: 0;
        }
    }

    &--lg {

        //@include mq-from(nsw-sm) {

            #{$root} {

                &__input {

                    &[type=text] {
                        font-size: rem(22);
                        line-height: vr(4);
                        height: vr(6);
                        padding: {
                            left: vr(2);
                            right: vr(2);
                        }
                    }
                }

                &__action {
                    padding: {
                        left: vr(1.75);
                        right: vr(1.75);
                    }
                    min-height: vr(6);

                    .Button__icon {
                        height: vr(2.25);
                    }
                }

                &__autocompleter {
                    right: vr(6);
                }

                &__suggestion {
                    padding: vr(1) vr(2);
                    font-size: rem(18);
                }
            }
        //}
    }
}
