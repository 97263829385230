.o-Flex {
    $root: &;

    margin-bottom: vr(-4);

    @include mq-from(block-bp1) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        @supports not (display: flex) {
            @include clearfix;
            max-width: calc(#{rem(300)} * 3 + #{vr(4)} * 2 * 3);
            margin: {
                left: auto;
                right: auto;
            }
        }
    }

    &__item {
        max-width: rem(300);
        margin: {
            left: auto;
            right: auto;
            bottom: vr(4);
        }

        @include mq-from(block-bp1) {
            flex: 0 1 rem(300);
            margin: {
                left: vr(4);
                right: vr(4);
            }

            @supports not (display: flex) {
                float: left;
                width: rem(300);
            }
        }
    }
}
