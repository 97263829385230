@use "sass:math";
@supports not (display: grid) {

    .o-Grid {
        $root: &;

        display: block;
        margin-bottom: vr(-3);

        &__cell {
            margin-bottom: vr(3);
        }

        &.-cols {

            &-2,
            &-3 {

                @include mq-from(grid-bp1) {
                    @include clearfix;
                }

                > #{$root} {

                    &__cell {

                        @include mq-from(grid-bp1) {
                            float: left;
                            width: calc(50% - #{vr(0.75)});
                            margin-right: vr(1.5);

                            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                width: calc(50% - #{vr(0.75)} - #{vr(0.1)});
                            }
                        }

                        @include mq-frombefore(grid-bp1, grid-bp3) {

                            &:nth-child(odd) {
                                clear: both;
                            }

                            &:nth-child(even) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            &-2 {

                > #{$root} {

                    &__cell {

                        @include mq-from(grid-bp3) {

                            &:nth-child(odd) {
                                clear: both;
                            }

                            &:nth-child(even) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            &-3 {

                > #{$root} {

                    &__cell {

                        @include mq-from(grid-bp3) {
                            width: calc(#{math.div(100%, 3)} - #{vr(1)});
                            margin-right: vr(1.5);

                            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                width: calc(#{math.div(100%, 3)} - #{vr(1)} - #{vr(0.1)});
                            }
                        }

                        @include mq-from(grid-bp3) {

                            &:nth-child(3n) {
                                margin-right: 0;
                            }

                            &:nth-child(3n+1) {
                                clear: both;
                            }
                        }
                    }
                }
            }
        }
    }
}
