.ElementArticles {
    $root: &;

    &__grid {
        display: grid;
        grid-gap: vr(1.5);
        justify-items: stretch;
    }

    &__article {

    }

    &.-cols {

        &-1 {
            max-width: rem(880);
            margin: {
                left: auto;
                right: auto;
            }

            #{$root} {

                &__grid {
                    grid-template-columns: repeat(auto-fill, minmax(#{rem(1000)}, 1fr));

                    @supports (width: unquote('min(#{rem(1000)}, 100%)')) {
                        grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(1000)}, 100%)'), 1fr));
                    }
                }
            }
        }

        &-2 {

            #{$root} {

                &__grid {
                    grid-template-columns: repeat(auto-fill, minmax(#{rem(460)}, 1fr));

                    @supports (width: unquote('min(#{rem(500)}, 100%)')) {
                        grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(460)}, 100%)'), 1fr));
                    }
                }
            }
        }

        &-3 {
            max-width: rem(880);
            margin: {
                left: auto;
                right: auto;
            }

            #{$root} {

                &__grid {
                     grid-template-columns: repeat(auto-fill, minmax(#{rem(260)}, 1fr));

                     @supports (width: unquote('min(#{rem(260)}, 100%)')) {
                         grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(260)}, 100%)'), 1fr));
                     }
                 }
            }
        }

        &-4 {

            #{$root} {

                &__grid {
                    grid-template-columns: repeat(auto-fill, minmax(#{rem(250)}, 1fr));

                    @supports (width: unquote('min(#{rem(250)}, 100%)')) {
                        grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(250)}, 100%)'), 1fr));
                    }
                }
            }
        }
    }
}
