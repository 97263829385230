.ModalMenu {
    $root: &;

    &__items {

    }

    &__item {
        border-bottom: rem(1) solid rgba($color-brand-white, 0.2);

        &.is-open {

            #{$root} {

                &__link {
                    background-color: rgba($color-brand-white, 0.2);
                }

                &__submenu {
                    display: block;
                }

                &__down {
                    display: none;
                }

                &__up {
                    display: inline-block;
                }
            }
        }

        &--current,
        &--section {

            #{$root}__link {
                border-left: vr(0.5) solid $color-brand-warmorange;
                font-weight: $font-weight-bold;
                color: $color-brand-warmorange;
            }
        }

        &:hover,
        &:active,
        &:focus {

            #{$root}__link {
                background-color: rgba($color-brand-white, 0.2);
            }
        }
    }

    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: {
            top: vr(1.25);
            bottom: vr(1.25);
            left: vr(1.5);
            right: vr(2);
        }
        width: 100%;

        font-weight: $font-weight-medium;
        font-size: rem(16);
        line-height: vr(1.5);

        //background-color: $color-brand-ghost;
        color: $color-brand-white;

        border-left: vr(0.5) solid transparent;
    }

    &__label {

    }

    &__down {
        flex: 0 0 auto;
        height: vr(2);
    }

    &__up {
        display: none;
        flex: 0 0 auto;
        height: vr(2);
    }

    &__submenu {
        display: none;
    }

    &__subitems {

    }

    &__subitem {

        &:first-child {
            border-top: rem(1) solid $color-brand-pacificblue;
        }

        &:not(:last-child) {
            border-bottom: rem(1) solid rgba($color-brand-white, 0.05);
        }
    }

    &__sublink {
        display: block;
        padding: {
            top: vr(1.25);
            bottom: vr(1.25);
            left: vr(2);
            right: vr(2);
        }

        background-color: rgba($color-brand-white, 0.2);
        color: $color-brand-white;
        font-weight: $font-weight-medium;

        border-left: vr(0.5) solid transparent;

        font-size: rem(14);
        line-height: vr(1.25);

        &--current,
        &--section {
            //background-color: $color-brand-ghost;
            border-left: vr(0.5) solid $color-brand-warmorange;
            font-weight: $font-weight-bold;
            color: $color-brand-warmorange;
        }

        &:hover,
        &:active,
        &:focus {

            #{$root}__subtitle {
                text-decoration: underline;
            }
        }
    }

    &__subtitle {
        display: block;
    }

    &__subdescription {
        display: block;
        margin-top: vr(0.5);
        font-size: rem(13);
        line-height: vr(1.25);
        font-weight: $font-weight-normal;
    }

    &--sidebar {

        #{$root} {

            &__item {
                border-bottom-color: $color-brand-lightgrey-light;

                &.is-open {
                    background-color: $color-brand-lightgrey-pale;

                    #{$root} {

                        &__link {
                            background-color: $color-brand-lightgrey-pale;
                        }

                        &__submenu {
                            display: block;
                        }

                        &__down {
                            display: none;
                        }

                        &__up {
                            display: inline-block;
                        }
                    }
                }
            }

            &__link {
                color: $color-brand-darkgrey;
                padding-left: vr(1);

                &:hover,
                &:active,
                &:focus {
                    color: $color-brand-darkgrey;
                    text-decoration: underline;
                    background-color: $color-brand-lightgrey-pale;
                }

                &--current, &--section {
                    color: $color-brand-darkgrey;
                    border-left-color: $color-brand-warmorange;
                    background-color: $color-brand-lightgrey-pale;
                    border-left-width: vr(0.25);
                    padding-left: vr(1.25);
                    font-weight: $font-weight-semi-bold;
                }
            }

            &__subitem {

                &:first-child {
                    border-top-color: $color-brand-lightgrey-light;
                }

                &:not(:last-child) {
                    border-bottom-color: $color-brand-lightgrey-light;
                }

                &:last-child {
                    border-bottom: rem(1) solid $color-brand-lightgrey;
                }
            }

            &__sublink {
                color: $color-brand-darkgrey;
                padding-left: vr(1.5);

                &:hover,
                &:active,
                &:focus {
                    color: $color-brand-darkgrey;
                    text-decoration: underline;
                }

                &--current, &--section {
                    color: $color-brand-darkgrey;
                    border-left-color: $color-brand-warmorange;
                    border-left-width: vr(0.25);
                    padding-left: vr(1.75);
                    font-weight: $font-weight-semi-bold;
                }
            }
        }
    }
}
