$font-family-sans-serif: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif !default;
$font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif !default;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace !default;

$font-weight-thin: 100 !default;
$font-weight-extra-light: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-extra-bold: 800 !default;
$font-weight-heavy: 900 !default;

/* Relative weights ref:
   https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Meaning_of_relative_weights
 */
$font-weight-lighter: lighter !default;
$font-weight-bolder: bolder !default;

$unicode-range-latin: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD !default;

$base-font-size: 100% !default;
$base-line-height: 1.5 !default;
