@use "sass:math";
$form-margin-unit: vr(2);

.s-UserForm {

	.error-container {
		margin-bottom: $form-margin-unit * 2;
	    background-position: vr(1) center;
	    background-size: vr(1.5);
	    background-repeat: no-repeat;
        background-image: #{assetspath('svgs/alerts/error.svg')};
	    padding: {
	        top: vr(1);
	        bottom: vr(1);
	        left: vr(3.5);
	        right: vr(2);
	    }
	}

    .field {

        &.hide {
            display: block;
            height: 0;
            overflow: hidden;

            &:last-child:not(:first-child) {
                margin-top: $form-margin-unit * -1;
            }
        }

        .CompositeField {

            > p.left {
                display: block;
                //font-size: rem(16);
                //line-height: vr(1.5);
                //font-weight: $font-weight-medium;
                margin-bottom: math.div($form-margin-unit, 4);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.optionset,
        &.checkboxset {

            > legend.left,
            > label.left {
                display: block;
                //font-size: rem(16);
                //line-height: vr(1.5);
                //font-weight: $font-weight-medium;
                margin-bottom: math.div($form-margin-unit, 4);

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .middleColumn {

                > .odd,
                > .even {
                    margin-bottom: math.div($form-margin-unit, 4);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    h1, h2, h3,
    h4, h5, h6 {
        color: currentColor;
        margin-bottom: $cmscontent-margin-unit;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    h1 {
        font-size: rem(22);
        font-weight: $font-weight-bold;
        line-height: vr(2.25);
        text-transform: uppercase;
        margin-top: $cmscontent-margin-unit * 2.5;

        @include mq-from(cmscontent-bp1) {
            font-size: rem(30);
            line-height: vr(2.75);
        }

        @include mq-from(cmscontent-bp2) {
            font-size: rem(36);
            line-height: vr(3.5);
        }
    }

    h2 {
        font-size: rem(22);
        font-weight: $font-weight-normal;
        line-height: vr(2.25);
        margin-top: $cmscontent-margin-unit * 2.25;

        @include mq-from(cmscontent-bp1) {
            font-size: rem(30);
            line-height: vr(3);
        }
    }

    h3 {
        color: currentColor;
        font-size: rem(20);
        font-weight: $font-weight-bold;
        line-height: vr(2);
        text-transform: uppercase;
        margin-top: $cmscontent-margin-unit * 2;
    }

    h4 {
        font-size: rem(20);
        font-weight: $font-weight-normal;
        line-height: vr(2);
        margin-top: $cmscontent-margin-unit * 2;
    }

    h5 {
        font-size: rem(18);
        font-weight: $font-weight-bold;
        line-height: vr(2);
        margin-top: $cmscontent-margin-unit * 1.75;
    }

    h6 {
        color: currentColor;
        font-size: rem(18);
        font-weight: $font-weight-bold;
        line-height: vr(2);
        text-transform: uppercase;
        margin-top: $cmscontent-margin-unit * 1.75;
    }

    > fieldset,
    .userform-fields {

        > .form-step.field {
            margin-bottom: 0;
        }

        + .step-navigation,
        + .Actions {
            margin-top: $form-margin-unit;
        }
    }

    .step-navigation {

        .step-buttons {
            @include clearfix;
        }

        .step-button-wrapper {
            margin-bottom: math.div($form-margin-unit, 2);

            &:last-child {
                margin-bottom: 0;
            }

            // TODO: remove this once .Button is made responsive
            @include mq-before(userforms-controls-bp1) {
                .Button {
                    display: block;
                    width: 100%;

                    @supports (display: flex) {
                        display: flex;
                    }
                }
            }

            @include mq-from(userforms-controls-bp1) {
                float: left;
                margin: {
                    bottom: 0;
                    right: math.div($form-margin-unit, 2);
                }

                &:last-child {
                    margin-right: 0;
                }

                &.Actions {
                    float: right;
                }
            }
        }
    }

    .Actions {
        @include clearfix;
        margin-bottom: $form-margin-unit;

        &:last-child {
            margin-bottom: 0;
        }

        > .action {
            float: right;
            margin-left: vr(1);

            &:last-child {
                margin-left: 0;
            }
        }
    }

    .userform-progress {
        position: relative;
        margin-bottom: $form-margin-unit * 1.5;

        &:last-child {
            margin-bottom: 0;
        }

        > p {
            margin-bottom: math.div($form-margin-unit, 2);
            font-weight: $font-weight-normal;

            @include mq-from(wizard-nav-bp1) {
                display: none;
            }

            > .current-step-number {}

            > .total-step-number {
                font-weight: $font-weight-normal;
            }
        }

        .progress {
            position: relative;
            width: 100%;
            height: vr(2);
            border-radius: rem(100);
            padding: {
                left: vr(0.5);
                right: vr(0.5);
            }

            @include mq-from(wizard-nav-bp1) {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
                height: vr(1);
            }

            .progress-bar {
                position: relative;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                height: vr(1);
                border-radius: rem(100);
                width: 0;
                transition: width 0.2s ease;

                @include mq-from(wizard-nav-bp1) {
                    display: none;
                }
            }
        }

        > nav {
            position: relative;
            height: 0;
            overflow: hidden;

            @include mq-before(wizard-nav-bp1) {
                pointer-events: none;
            }

            @include mq-from(wizard-nav-bp1) {
                display: block;
                height: auto;
                overflow: auto;
            }

            .step-buttons {
                display: table;
                vertical-align: middle;
                width: 100%;
                padding: {
                    left: vr(2);
                    right: vr(2);
                }

                .step-button-wrapper {
                    display: table-cell;
                    text-align: center;
                    border-radius: 50%;
                    transition: all 0.1s ease;

                    &:focus-within {

                        .step-button-jump:focus {
                            outline: 0 none;
                        }
                    }

                    .step-button-jump {
                        left: auto !important;
                        margin: {
                            top: rem(2);
                            bottom: rem(2);
                            left: rem(2) !important;
                            right: rem(2);
                        }

                        //font-family: $font-family-buttons;
                        width: vr(6.5);
                        height: vr(6.5);
                        display: inline-block;
                        font-size: rem(24);
                        line-height: 1;
                        font-weight: $font-weight-bold;
                        text-transform: uppercase;
                        cursor: pointer;
                        border: {
                            width: vr(1);
                            style: solid;
                        }
                        border-radius: 50%;
                        padding: vr(1);
                        transition: all 0.1s ease;

                        &[disabled] {
                            cursor: not-allowed;
                        }
                    }
                }
            }

            @supports (display: flex) {

                .step-buttons {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    .step-button-wrapper {
                        flex: 0 1 auto;
                        display: block;

                        &.viewed {}
                        &.current {}

                        .step-button-jump {

                            &[disabled] {}
                        }
                    }
                }
            }
        }
    }

    .progress-title {
        @include text-font-smoothing;
        //font-family: $font-family-headings;
        margin-bottom: $form-margin-unit;
        font-size: rem(26);
        font-weight: $font-weight-bold;
        line-height: vr(2.5);

        &:last-child {
            margin-bottom: 0;
        }
    }
}
