$el-sectionnav-bordercolor: rgba($color-brand-grey-calm, 0.25);
$el-sectionnav-link-fontweight: $font-weight-medium;
$el-sectionnav-link-hover-bgcolor: rgba(0,0,0,0.05);

.ElementSectionNav {

    &__item {
        border-top: 2px solid $el-sectionnav-bordercolor;

        &:last-child {
            border-bottom: 2px solid $el-sectionnav-bordercolor;
        }
    }

    &__link {
        display: block;
        padding: 12px 6px;

        &--current,
        &--section {
            font-weight: $el-sectionnav-link-fontweight;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: $el-sectionnav-link-hover-bgcolor;
        }
    }

    &__subItems {

    }

    &__subItem {

    }

    &__subLink {
        display: block;
    }
}
