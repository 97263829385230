.Accordion {
    $root: &;

    border-bottom: rem(1) solid rgba($color-brand-grey-calm, 0.25);;

    &__hd {
        border-top: rem(1) solid rgba($color-brand-grey-calm, 0.25);;
        padding: {
            top: vr(2);
            bottom: vr(2);
        }
    }

    &__bd {
        //max-height: 75vh !important;
        overflow: hidden;

        // scss-lint:disable ImportantRule
        &.is-hidden {
            max-height: 0 !important;
            visibility: hidden;
        }
    }

    &__button {
        color: inherit;
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding: 0;
        box-shadow: none;
        padding: {
            top: vr(2);
            bottom: vr(2);
            left: 0;
            right: vr(3);
        }
        display: block;
        width: 100%;
        cursor: pointer;
        position: relative;

        &:focus {
            outline: none;
            //outline: rem(2) solid rgba($color-brand-blue-bright-vibrant-strong, 0.6);
        }

        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: rem(15);
            height: rem(15);
            background: transparent #{assetspath('svgs/icons/angle-down-light.svg')} no-repeat scroll center center;
            background-size: cover;
            transition: transform .2s ease-in-out;
        }

        &.-ba-is-active {

            &:before {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    &__title {
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        font-size: rem(18);
        line-height: vr(2);
        text-align: left;

        + #{$root}__description {
            margin-top: vr(0.5);
        }
    }

    &__description {
        font-size: rem(14);
        line-height: vr(1.5);
        text-align: left;
    }

    &__content {
        padding-bottom: vr(2);
    }

    &.is-initialized {

        #{$root} {

            &__hd {
                padding: {
                    top: 0;
                    bottom: 0;
                }
            }

            &__bd {
                transition: max-height ease-in-out 0.2s;
            }
        }
    }

    &--sm {

        #{$root} {

            &__hd,
            &__button {
                padding: {
                    top: vr(1.5);
                    bottom: vr(1.5);
                }
            }

            &__title {
                font-size: rem(18);
                line-height: vr(2);
            }
        }
    }
}

.-theme {
    $root: '.Accordion';

    &-blue-expert,
    &-blueexpert {

        #{$root} {
            border-bottom-color: $color-brand-blue-pale;

            &__hd {
                border-top-color: $color-brand-blue-pale;
            }
        }
    }

    &-blue-empower,
    &-blueempower {

        #{$root} {
            border-bottom-color: $color-brand-blue-harmony;

            &__hd {
                border-top-color: $color-brand-blue-harmony;
            }
        }
    }

    &-blue-true,
    &-bluetrue {

        #{$root} {
            border-bottom-color: $color-brand-blue-harmony;

            &__hd {
                border-top-color: $color-brand-blue-harmony;
            }
        }
    }

    &-blue-harmony,
    &-blueharmony {

        #{$root} {
            border-bottom-color: rgba($color-brand-grey-calm, 0.25);

            &__hd {
                border-top-color: rgba($color-brand-grey-calm, 0.25);
            }
        }
    }

    &-yellow-action,
    &-yellowaction {

        #{$root} {
            border-bottom-color: $color-brand-yellow-light;

            &__hd {
                border-top-color: $color-brand-yellow-light;
            }
        }
    }

    &-grey-light,
    &-greylight {

        #{$root} {
            border-bottom-color: rgba($color-brand-grey-calm, 0.25);

            &__hd {
                border-top-color: rgba($color-brand-grey-calm, 0.25);
            }
        }
    }

    &-black {

        #{$root} {
            border-bottom-color: rgba($color-brand-grey-calm, 0.25);

            &__hd {
                border-top-color: rgba($color-brand-grey-calm, 0.25);
            }
        }
    }

    &-white,
    &-default {

        #{$root} {
            border-bottom-color: rgba($color-brand-grey-calm, 0.25);

            &__hd {
                border-top-color: rgba($color-brand-grey-calm, 0.25);
            }
        }
    }

    &-grey-calm,
    &-greycalm {

        #{$root} {
            border-bottom-color: $color-brand-blue-harmony;

            &__hd {
                border-top-color: $color-brand-blue-harmony;
            }
        }
    }
}
