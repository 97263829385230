.l-Page {
    $root: &;

    &__container {

    }

    &__crumbs {
        margin-top: vr(1.5);

        &:not(:last-child) {
            margin-bottom: vr(1.5);
        }

        + #{$root}__main {
            margin-top: vr(2.25);
        }
    }

    &__header {
        margin-bottom: vr(3);
    }

    &__title {
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        font-size: rem(40);
        line-height: vr(4);

        &:not(:last-child) {
            margin-bottom: vr(3);

            + #{$root}__subtitle {
                margin-top: vr(-2);
            }
        }
    }

    &__subtitle {
        font-size: rem(18);
        line-height: vr(2);
        font-weight: $font-weight-medium;
        color: $color-brand-darkgrey-pale;
        margin-bottom: vr(3);
    }

    &__main {
        max-width: $content-max-width;
        margin: {
            left: 0;
            right: auto;
            top: vr(5);
            bottom: vr(6);
        }

        &--sidebar {
            max-width: none;
            display: grid;
            grid-gap: vr(5);
            grid-template-columns: none;

            @include mq-from(site-bp4) {
                grid-gap: vr(3);
                grid-template-columns: 230px auto;
                grid-template-areas: 'sidebar primary';

                @supports not (display: grid) {
                    @include clearfix;
                }
            }

            @include mq-from(site-bp5) {
                grid-template-columns: 300px auto;
                grid-column-gap: vr(5);
                grid-row-gap: vr(1.75);
            }

            #{$root} {

                &__primary {
                    order: 1;

                    @include mq-from(site-bp4) {
                        grid-area: primary;

                        @supports not (display: grid) {
                            float: right;
                            width: calc(100% - 20.1%);
                            padding-left: vr(3);
                        }
                    }

                    @include mq-from(site-bp5) {

                        @supports not (display: grid) {
                            float: right;
                            width: calc(100% - 300px);
                            padding-left: vr(3);
                        }
                    }
                }

                &__sidebar {
                    order: 2;

                    @supports not (display: grid) {
                        margin-top: vr(5);
                    }

                    @include mq-from(site-bp4) {
                        grid-area: sidebar;

                        @supports not (display: grid) {
                            float: left;
                            width: 20%;
                            margin-top: 0;
                        }
                    }

                    @include mq-from(site-bp5) {

                        @supports not (display: grid) {
                            width: 300px;
                        }
                    }
                }
            }
        }
    }

    &__primary {

    }

    &__sidebar {

    }

    &__component {

        &:not(:first-child) {
            margin-top: vr(2.25);
        }

        &:not(:last-child) {
            margin-bottom: vr(2.25);
        }
    }

    &__section {

        &:not(:first-child) {
            margin-top: vr(4.5);
        }

        &:not(:last-child) {
            margin-bottom: vr(4.5);
        }
    }

    &__form {
        max-width: rem(600);
    }

    &__block {

        &:not(:first-child) {
            margin-top: vr(2.5);
        }

        &:not(:last-child) {
            margin-bottom: vr(2.5);
        }
    }
}
