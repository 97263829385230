.BlogIndexFilterState {
    $root: &;

    margin-top: vr(-1);

    &__title {
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        font-size: rem(20);
        line-height: vr(2.25);
        color: $color-brand-blue-expert;
    }

    &__attr {

    }

    &__value {
        font-style: italic;
        color: $color-brand-blue-empower;
    }

    &__clear {
        display: inline-block;
        font-size: rem(14);
        line-height: vr(1.5);
        color: $color-brand-grey-strong;

        &:hover,
        &:active {
            text-decoration: underline;
            color: $color-brand-grey-calm;
        }
    }
}
