$site-gutters: map-get($site-container, gutters);

.o-Grid {
    $root: &;

    display: grid;
    //grid-gap: vr(1.5);
    justify-items: center;
    grid-template-columns: repeat(auto-fill, minmax(#{rem(250)}, 1fr));
    grid-gap: nth($site-gutters, 2);

    @include mq-from(container-bp2) {
        grid-gap: vr(3);
        //grid-gap: nth($site-gutters, 3);
    }

    @supports (width: unquote('min(#{rem(250)}, 100%)')) {
        grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(250)}, 100%)'), 1fr));
    }

    &__cell {
        width: 100%;
    }

    &.-cols {

        &-1 {
            grid-template-columns: repeat(auto-fill, minmax(#{rem(1000)}, 1fr));

            @supports (width: unquote('min(#{rem(1000)}, 100%)')) {
                grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(1000)}, 100%)'), 1fr));
            }
        }

        &-2 {
            grid-template-columns: repeat(auto-fill, minmax(#{rem(500)}, 1fr));

            @supports (width: unquote('min(#{rem(500)}, 100%)')) {
                grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(500)}, 100%)'), 1fr));
            }
        }

        &-3 {
            grid-template-columns: repeat(auto-fill, minmax(#{rem(375)}, 1fr));

            @supports (width: unquote('min(#{rem(375)}, 100%)')) {
                grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(375)}, 100%)'), 1fr));
            }
        }

        &-33 {
            grid-gap: vr(3);
            grid-template-columns: repeat(auto-fill, minmax(#{rem(290)}, 1fr));

            @supports (width: unquote('min(#{rem(290)}, 100%)')) {
                grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(290)}, 100%)'), 1fr));
            }
        }

        &-4 {
            grid-template-columns: repeat(auto-fill, minmax(#{rem(250)}, 1fr));

            @supports (width: unquote('min(#{rem(250)}, 100%)')) {
                grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(250)}, 100%)'), 1fr));
            }
        }

        &-stats {
            grid-template-columns: repeat(auto-fill, minmax(#{rem(420)}, 1fr));

            @supports (width: unquote('min(#{rem(420)}, 100%)')) {
                grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(420)}, 100%)'), 1fr));
            }
        }

        &-people {
            grid-gap: vr(4);
            grid-template-columns: repeat(auto-fill, minmax(#{rem(220)}, 1fr));

            @supports (width: unquote('min(#{rem(220)}, 100%)')) {
                grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(220)}, 100%)'), 1fr));
            }
        }
    }

    &.-tiles {
        grid-template-columns: repeat(auto-fill, minmax(#{rem(140)}, 1fr));

        @supports (width: unquote('min(#{rem(140)}, 100%)')) {
            grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(140)}, 100%)'), 1fr));
        }
    }
}
