.Pagination {
    $root: &;

    display: flex;
    //padding: vr(1.5);
    justify-content: space-between;
    align-items: center;
    //border-top: rem(1) solid $color-brand-lightgrey-light;

    &__position {

    }

    &__showing {
        font-weight: $font-weight-medium;
        padding: {
            left: rem(1);
            right: rem(1);
        }
    }

    &__items {
        @include clearfix;
    }

    &__item {
        position: relative;
        float: left;
        width: vr(3.5);
        border: rem(1) solid rgba($color-brand-lightgrey, 0.75);

        @include mq-from(pagination-bp1) {
            width: vr(4);
        }

        &:first-child {
            border: {
                top-left-radius: vr(0.5);
                bottom-left-radius: vr(0.5);
            }

            #{$root} {
                &__link,
                &__text {
                    border: {
                        top-left-radius: vr(0.5);
                        bottom-left-radius: vr(0.5);
                    }
                }
            }
        }

        &:not(:first-child):not(#{$root}__item--current) {
            border-left: 0;
        }

        &:last-child {
            border: {
                top-right-radius: vr(0.5);
                bottom-right-radius: vr(0.5);
            }

            #{$root} {
                &__link,
                &__text {
                    border: {
                        top-right-radius: vr(0.5);
                        bottom-right-radius: vr(0.5);
                    }
                }
            }
        }

        &--previous {

        }

        &--next {

            #{$root} {

                &__link {
                    border: {
                        top-right-radius: vr(0.5);
                        bottom-right-radius: vr(0.5);
                    }
                }
            }
        }

        &--page {

        }

        &--current {
            border-color: $color-brand-warmorange;
            z-index: z(site-body);

            &:not(:first-child) {
                margin-left: rem(-1);
            }

            &:not(:last-child) {
                margin-right: rem(-1);
            }

            #{$root} {

                &__text {
                    background-color: rgba($color-brand-warmorange, 0.05);
                    color: $color-brand-warmorange;
                }
            }
        }

        &--hellip {

            #{$root} {

                &__text {
                    font-size: rem(20);
                }
            }
        }
    }

    &__link,
    &__text {
        display: block;
        width: 100%;
        text-align: center;
        font-size: rem(18);
        font-weight: $font-weight-medium;
        line-height: vr(4);
        background-color: $color-brand-white;
        color: $color-brand-darkgrey-light;
    }

    &__link {

        &:hover,
        &:active,
        &:focus {
            background-color: $color-brand-lightgrey-light;
        }

        &:focus {
            outline: rem(2) solid $color-brand-white;
            outline-offset: rem(-6);
        }
    }

    &__text {
        cursor: default;
    }

    &.-align {

        &-left {

            @include mq-from(pagination-bp1) {
                justify-content: flex-start;
            }
        }

        &-center {
            // default
        }

        &-right {

            @include mq-from(pagination-bp1) {
                justify-content: flex-end;
            }
        }
    }
}
