.o-BodyBlock {
    $root: &;

    &__container {
        position: relative;
    }

    &__hd,
    &__bd {
        position: relative;
    }

    &__hd {
        max-width: $content-max-width;

        + #{$root}__bd {
            margin-top: vr(3);
        }
    }

    &__title {
        font-size: rem(36);
        line-height: vr(3.5);
        font-weight: $font-weight-medium;
        margin-bottom: vr(2.5);

        &:last-child {
            margin-bottom: 0;
        }

        + #{$root}__subtitle {
            margin-top: vr(-0.75);
        }
    }

    &__subtitle {
        font-size: rem(24);
        font-weight: $font-weight-normal;
        line-height: vr(2.5);
    }

    &__element {
        margin-bottom: vr(3);

        &:last-child {
            margin-bottom: 0;
        }
    }

    // MODIFIERS

    &.-pad {

        &-bottom-off {

            #{$root}__container {
                margin-bottom: vr(-2);
            }
        }
    }

    &.-border {

        &-bottom-on {

            #{$root}__container {
                border-bottom: rem(1) solid rgba($color-brand-grey-calm, 0.25);
                padding-bottom: vr(5);
            }
        }
    }
}
