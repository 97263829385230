.Masthead {
    $root: &;

    background-color: $color-brand-white;
    color: $color-brand-pacificblue;
    font-weight: $font-weight-medium;
    border-bottom: rem(1) solid $color-brand-lightgrey-light;

    &__toolbar,
    &__primary {
        padding: {
            left: nth($site-gutters, 1);
            right: nth($site-gutters, 1);
        }
    }

    &__toolbar {
        display: none;
        background-color: rgba($color-brand-lightgrey-pale, 0.75);
        border-bottom: rem(1) solid rgba($color-brand-lightgrey-light, 0.75);

        @include mq-from(masthead-bp1) {
            display: block;
        }
    }

    &__primary {
        height: vr(7);
    }

    &__container {
        height: 100%;
        padding: {
            top: vr(1.25);
            bottom: vr(1.25);
        }
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: calc(#{map-get($site-container, max-width)} + #{nth($site-gutters, 3)} + #{nth($site-gutters, 3)} - #{nth($site-gutters, 1)} - #{nth($site-gutters, 1)});
        width: 100%;
        margin: {
            left: auto;
            right: auto;
        }

        @include mq-from(masthead-bp0) {
            justify-content: space-between;
            padding: {
                top: vr(1);
                bottom: vr(1);
            }
        }

        &--toolbar {
            padding: {
                top: 0;
                bottom: 0;
            }
        }
    }

    &__logo {
        height: 100%;
    }

    &__main {
        //display: none;
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;

        //@include mq-from(masthead-bp1) {
        //    display: flex;
        //}
    }

    &__menu {
        display: none;

        @include mq-from(masthead-bp2) {
            display: block;
        }
    }

    &__actions {
        display: none;
        margin-left: auto;
        align-items: center;

        @include mq-from(masthead-bp0) {
            display: flex;
        }
    }

    &__action {

        &:not(:first-child) {
            margin-left: vr(1);

            @include mq-from(masthead-bp3) {
                margin-left: vr(1.25);
            }

            @include mq-from(masthead-bp4) {
                margin-left: vr(1.5);
            }
        }

        &--search {
            display: none;

            @include mq-from(masthead-bp1) {
                display: block;
            }
        }

        &--cta {
            display: none;

            @include mq-from(masthead-bp1) {
                display: block;
            }
        }

        &--menu {

            @include mq-from(masthead-bp2) {
                display: none;
            }
        }
    }
}
