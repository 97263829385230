// Visually hiding content that will be read by a screen reader
// @see https://webaim.org/techniques/css/invisiblecontent/#absolutepositioning
@mixin visually-hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

@mixin undo-visually-hidden {
    position: static;
    left: auto;
    top: auto;
    width: auto;
    height: auto;
    overflow: auto;
}
