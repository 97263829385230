.Dock {
    $root: &;

    position: relative;
    background-color: $color-brand-lightgrey-pale;
    color: $color-brand-darkgrey;
    border-top: rem(1) solid $color-brand-lightgrey-light;

    &__items {
        display: flex;
    }

    &__item {
        font-weight: $font-weight-medium;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;

        &:not(:last-child) {
            border-right: rem(2) solid $color-brand-white;
        }

        &--highlight {

            #{$root} {

                &__icon {
                    color: currentColor;
                }

                &__link {
                    background-color: $color-brand-pacificblue-pale;
                    color: $color-brand-white;

                    &:hover {
                        background-color: $color-brand-pacificblue-light;
                    }

                    &:active,
                    &:focus {
                        background-color: $color-brand-pacificblue-light;
                    }
                }
            }
        }

        @include mq-before(dock-bp1) {

            &:nth-child(3n) {
                display: none;
            }
        }

        @include mq-from(dock-bp1) {
            width: 20%;
        }
    }

    &__link {
        display: block;
        text-align: center;
        text-decoration: none;
        width: 100%;
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: inherit;
        font-size: rem(12);
        line-height: vr(1.25);
        padding: vr(1) vr(0.5);

        @include mq-from(dock-bp2) {
            font-size: rem(14);
            line-height: vr(1.25);
            padding: {
                top: vr(1.25);
                bottom: vr(1.25);
            }
        }

        @include mq-from(dock-bp3) {
            font-size: rem(16);
            line-height: vr(1.5);
        }

        &:hover {
            background-color: $color-brand-lightgrey-light;
        }

        &:active,
        &:focus {
            background-color: $color-brand-lightgrey-light;
        }

        &:focus {
            outline: rem(2) solid $color-brand-white;
            outline-offset: rem(-6);
        }
    }

    &__label {
        display: block;
        margin-top: vr(0.5);

        @include mq-from(dock-bp2) {
            margin-top: vr(0.75);
        }
    }

    &__icon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        height: vr(1.5);
        width: auto;
        min-width: rem(1);
        color: $color-brand-warmorange;

        @include mq-from(dock-bp1) {
            height: vr(1.75);
        }
    }
}
