.AttributesList {
    @include clearfix;
    margin-bottom: vr(-1);

    &__attribute {
        float: left;
        margin-right: vr(1);
        margin: {
            right: vr(1);
            bottom: vr(1);
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
