.ElementQuotation {
    $root: &;

    max-width: $content-max-width;

    &__quote {
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        font-size: rem(48);
        line-height: vr(4.75);
    }

    &__attribution {
        font-size: rem(20);
        margin-top: vr(1.5);
        font-weight: $font-weight-normal;
        color: $color-brand-grey;
    }
}
