.MetaList {
    $root: &;

    &--block {

        #{$root} {

            &__attribute,
            &__items {
                display: block;
            }

            &__attribute {
                position: relative;
                padding-left: vr(2.5);
                margin-bottom: vr(1);

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__icon {
                position: absolute;
                top: rem(3);
                left: 0;
            }
        }
    }

    &--inline {

        #{$root} {

            &__attribute,
            &__items {
                display: inline;
            }

            &__attribute {
                padding-right: vr(2);
            }

            &__icon {
                display: inline-block;
                vertical-align: middle;
                padding-right: vr(0.5);
                top: rem(-2);
            }
        }
    }

    &__attribute {

    }

    &__icon {
        height: vr(1.5);
        width: auto;
        opacity: 0.55;
    }

    &__items {

    }

    &__item {
        display: inline;

        &:not(:last-child) {

            #{$root} {

                &__value,
                &__link {

                    &:after {
                        content: ', ';
                    }
                }
            }
        }
    }

    &__link,
    &__value {
        display: inline;
        color: inherit;
        font-weight: $font-weight-normal;
        opacity: 0.75;
    }

    &__link {

        &:hover,
        &:active,
        &:focus {
            opacity: 1;

            #{$root}__text {
                text-decoration: underline;
            }
        }

        &:focus {
            outline: {
                width: rem(1);
                style: solid;
                color: currentColor;
            }
        }
    }
}
