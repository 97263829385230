.o-PageBlock {
    $root: &;

    &__title {
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        font-size: rem(22);
        line-height: vr(2.25);
        margin-bottom: vr(2);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__bd {

    }
}
