.LinksListItem {
    $root: &;

    position: relative;
    width: 100%;
    padding: {
        top: vr(2);
        bottom: vr(2);
    }

    &:hover {
        #{$root}__icon {
            transform: translateY(-50%) scale(1.5);
        }
    }

    &:focus-within {
        #{$root}__icon {
            transform: translateY(-50%) scale(1.5);
        }
    }

    &__title {
        font-size: rem(22);
        font-weight: $font-weight-normal;
        line-height: vr(2.25);
        margin-bottom: vr(0.5);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        display: block;
        position: static;
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
            color: $color-brand-green-dark;
        }

        &:focus {
            outline: none;
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &__summary {
        font-size: rem(16);
        line-height: vr(2);
        font-weight: $font-weight-normal;
        color: $color-brand-grey;
    }

    &__icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 0;
        height: vr(2);
        width: vr(2);
        transition: transform 0.2s ease;
    }
}
