.Footer {
    $root: &;

    background-color: $color-brand-pacificblue;
    color: $color-brand-white;
    border-top: rem(1) solid rgba($color-brand-black, 0.1);

    padding-bottom: vr(8);
    @include mq-from(footer-bp1) {
        padding-bottom: 0;
    }

    &__main {
        padding: {
            top: vr(2.5);
            bottom: vr(2.5);
        }

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(#{rem(250)}, 1fr));
        grid-gap: vr(3);

        @supports (width: unquote('min(#{rem(250)}, 100%)')) {
            grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(250)}, 100%)'), 1fr));
        }
    }

    &__menu {
        font-size: rem(16);
        line-height: vr(2);
    }

    &__contacts {
        flex: 0 1 auto;
        width: 40%;
        margin-left: auto;
        display: flex;
        justify-content: flex-start;
        border-left: rem(1) solid rgba($color-brand-grey-calm, 0.25);
        font-size: rem(14);
        line-height: vr(1.5);
        padding: {
            top: vr(3);
            bottom: vr(3);
            left: vr(3);
        }
    }

    &__brand {
        flex: 0 1 auto;
        width: vr(20);
        padding-right: vr(3);
    }

    &__about {
        font-size: rem(14);
        line-height: vr(1.5);

        > a {
            text-decoration: none;

            &:hover,
            &:active,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    &__contact {
        flex: 0 1 auto;
        width: 50%;
        margin-left: auto;
    }

    &__supplementary {
        opacity: 0.75;
        border-top: rem(1) solid $color-brand-white;
        padding: {
            top: vr(1.5);
            bottom: vr(1.5);
        }
        text-align: center;
    }

    &__legals {
        position: relative;
        font-size: rem(14);
        line-height: vr(1.75);
        color: $color-brand-white;
    }

    &__legal {
        line-height: vr(2);

        @include mq-from(footer-bp1) {
            display: inline-block;

            &:after {
                content: '|';
                float: right;
                padding: 0 vr(1);
            }

            &:last-child:after {
                content: none;
            }
        }
    }

    &__legalLink {
        display: inline-block;
        color: currentColor;

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }
    }
}

.GovernmentBlock {

    &__logo {
        max-width: rem(250);
    }

    &__content {
        font-size: rem(14);
        line-height: vr(1.75);
        margin-top: vr(1.5);
        color: $color-brand-white;
    }
}
