.Submenu {
    $root: &;

    padding-top: vr(0.75);
    font-size: rem(15);

    @include mq-from(mainmenu-bp1) {
        font-size: rem(16);
    }

    &__items {
        background-color: $color-brand-white;
        color: $color-brand-darkgrey;
        border: rem(1) solid $color-brand-lightgrey-light;
    }

    &__item {
        position: relative;
        border-bottom: rem(1) solid $color-brand-lightgrey-light;

        &:first-child {

            #{$root}__link {

                &:before {
                    content: '';
                    display: block;
                    width: vr(1);
                    height: vr(1);
                    transform: rotate(45deg) translateX(-50%);
                    position: absolute;
                    top: rem(-2);
                    left: 50%;
                    background-color: $color-brand-white;
                    border: {
                        top: rem(1) solid $color-brand-lightgrey-light;
                        left: rem(1) solid $color-brand-lightgrey-light;
                    }
                }

                &:hover,
                &:active,
                &:focus {

                    &:before {
                        //background-color: $color-brand-lightgrey-light;
                    }
                }
            }
        }

        &:last-child {
            border-bottom: 0;
        }

        &--current,
        &--section {

            #{$root}__link {
                border-left: rem(4) solid $color-brand-warmorange-dark;
                color: $color-brand-warmorange-dark;
                padding-left: calc(#{vr(1.5)} - #{rem(4)});
                font-weight: $font-weight-bold;
            }

            #{$root}__description {
                font-weight: $font-weight-medium;
            }
        }
    }

    &__link {
        display: block;
        width: 100%;
        padding: {
            top: vr(1);
            bottom: vr(1);
            left: vr(1);
            right: vr(1);
        };

        @include mq-from(mainmenu-bp1) {
            padding: {
                left: vr(1.25);
                right: vr(1.25);
            };
        }

        @include mq-from(mainmenu-bp2) {
            padding: {
                left: vr(1.5);
                right: vr(1.5);
            };
        }

        &:hover,
        &:active,
        &:focus  {
            color: $color-brand-warmorange-dark;

            #{$root}__description {
                color: currentColor;
            }
        }
    }

    &__title {
        display: block;
    }

    &__description {
        display: block;
        margin-top: vr(0.25);
        font-size: rem(12);
        line-height: vr(1.25);
        font-weight: $font-weight-normal;
        color: $color-brand-darkgrey;

        @include mq-from(mainmenu-bp1) {
            font-size: rem(14);
            line-height: vr(1.5);
        }
    }

    &.-arrowpos-right {

        #{$root}__item {

            &:first-child {

                #{$root}__link {

                    &:before {
                        left: 75%;
                    }
                }
            }
        }
    }
}
