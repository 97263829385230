html {
    font-size: $base-font-size;
    line-height: $base-line-height;
    scroll-behavior: smooth;
    //overflow-y:scroll;
    scroll-padding-top: vr(9);
    scroll-padding-bottom: rem(20);

    @include mq-from(site-bp2) {
        scroll-padding-top: vr(11);
    }

    &:not(.is-keys) {

        * {

            &:focus {
                outline: none !important;
            }

            &:focus-within {
                outline: none !important;
            }
        }
    }
}

body {
    font-size: 100%;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: $base-line-height;
    font-family: $font-family-body;
    color: $color-body-text-color;
    background-color: $color-brand-white;
    cursor: auto;
    margin: 0;
    padding: 0;
    text-align: start;
    scroll-behavior: smooth;
    overscroll-behavior: none;

    @supports not (text-align:start) {
        text-align: left;
    }

    &.has-modal-open {
        overflow: hidden;
    }
}
