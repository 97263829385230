.ShareList {
    $root: &;

    //border: {
    //    top: rem(1) solid $color-brand-lightgrey-light;
    //    bottom: rem(1) solid $color-brand-lightgrey-light;
    //}
    //padding: {
    //    top: vr(1.5);
    //    bottom: vr(1.5);
    //}

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: vr(1.25);
    }

    &__item {
        display: inline-block;
    }
}


.resp-sharing-button__link,
.resp-sharing-button__icon {
    display: inline-block
}

.resp-sharing-button__link {
    text-decoration: none;
    color: #fff;
}

.resp-sharing-button {
    border-radius: 6px;
    transition: 25ms ease-out;
    padding: 0.5em 0.75em;
}

.resp-sharing-button__icon svg {
    width: 24px;
    height: 24px;
    margin-right: 0.4em;
    vertical-align: top
}

.resp-sharing-button--small svg {
    margin: 0;
    vertical-align: middle
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
    stroke: #fff;
    fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
    fill: #fff;
    stroke: none
}

.resp-sharing-button--twitter {
    background-color: #55acee
}

.resp-sharing-button--twitter:hover {
    background-color: #2795e9
}

.resp-sharing-button--facebook {
    background-color: #3b5998
}

.resp-sharing-button--facebook:hover {
    background-color: #2d4373
}

.resp-sharing-button--linkedin {
    background-color: #0077b5
}

.resp-sharing-button--linkedin:hover {
    background-color: #046293
}

.resp-sharing-button--email {
    background-color: #9B9A9A
}

.resp-sharing-button--email:hover {
    background-color: #686868
}

.resp-sharing-button--facebook {
    background-color: #3b5998;
    border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
    background-color: #2d4373;
    border-color: #2d4373;
}

.resp-sharing-button--twitter {
    background-color: #55acee;
    border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
    background-color: #2795e9;
    border-color: #2795e9;
}

.resp-sharing-button--email {
    background-color: #777777;
    border-color: #777777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
    background-color: #5e5e5e;
    border-color: #5e5e5e;
}

.resp-sharing-button--linkedin {
    background-color: #0077b5;
    border-color: #0077b5;
}

.resp-sharing-button--linkedin:hover,
.resp-sharing-button--linkedin:active {
    background-color: #046293;
    border-color: #046293;
}

.l-Page__block--share {
    $root: &;

    margin-top: 0;
    border-top: 0;
    padding-top: vr(3);

    .o-PageBlock {
        display: grid;
        grid-template-columns: rem(250) 1fr;
        align-items: center;

        &__title {
            margin-bottom: 0;
        }

        .MetaItems {
            text-align: right;

            &__list {
                line-height: vr(1.25);
            }

            &__listLink {
                color: $color-brand-white;
                padding: vr(0.75) vr(1);
                margin-right: vr(1);
                font-size: rem(16);
                background-color: #9B9A9A;

                &--facebook {
                    background-color: #3b5998;
                }

                &--twitter {
                    background-color: #55acee;
                }

                &--linkedin {
                    background-color: #0077b5;
                }
            }

            &__listItem:last-child {

                .MetaItems__listLink {
                    margin-right: 0;
                }
            }
        }
    }
}
