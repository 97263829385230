.AutocompleteTextField {
    $root: &;

    &__input {

    }

    &__holder {
        position: relative;
    }

    &__results {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        max-height: vr(17);
        overflow-y: scroll;
        background-color: $color-brand-white;
        border: {
            bottom: rem(1) solid rgba($color-brand-grey-calm,.65);
            left: rem(1) solid rgba($color-brand-grey-calm,.65);
            right: rem(1) solid rgba($color-brand-grey-calm,.65);
        }
        text-align: left;
        box-shadow: 0 rem(4) rem(12) 0 rgba($color-brand-black, 0.15);
    }

    &__result {
        display: block;
        background-color: $color-brand-white;
        color: rgba($color-brand-black, 0.85);
        padding: {
            top: vr(1);
            bottom: vr(1);
            left: vr(1);
            right: vr(8);
        };
        font-size: rem(14);
        line-height: vr(1.5);
        cursor: pointer;
        position: relative;

        &.selected,
        &:hover {
            background-color: $color-brand-grey-light;
            color: $color-brand-black;

            &:after {
                content: 'Press to select';
                font-size: rem(12);
                opacity: 0.5;
                position: absolute;
                top: 50%;
                right: vr(1);
                transform: translateY(-50%);
            }
        }

        &:active {
            background-color: $color-brand-grey-light;
            color: $color-brand-black;
        }
    }
}
