.Pill {
    display: inline-block;
    font-weight: $font-weight-semi-bold;
    font-size: rem(12);
    line-height: vr(1.5);
    white-space: nowrap;
    padding: {
        left: vr(1);
        right: vr(1);
        top: vr(0.5);
        bottom: vr(0.5);
    }
    border: rem(2) solid $color-brand-green-dark;
    background-color: $color-brand-green-dark;
    color: $color-brand-white;
    border-radius: vr(0.25);
    text-transform: uppercase;
    position: relative;

    &:not(.-interact-off) {

        &:hover,
        &:active,
        &:focus {
            border-color: $color-brand-green;
            background-color: $color-brand-green;
            color: $color-brand-white;
        }

        &:active {
            top: rem(2);
        }
    }

    &.-interact-off {
        cursor: default;
    }

    &.-ghost {
        background-color: transparent;
        color: $color-brand-green-dark;

        &:not(.-interact-off) {

            &:hover,
            &:active,
            &:focus {
                border-color: $color-brand-green;
                background-color: $color-brand-green;
                color: $color-brand-white;
            }
        }
    }
}
