.ElementEmbed {
    $root: &;

    &__code {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }
}

.fitVids-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
}
.fitVids-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ElementOEmbed {
    $root: &;

    &__ratio {
        position: relative;
        width: 100%;
        height: 0;
    }
}

.u-embed-ratio {
    position: relative;
    width: 100%;
    height: 0;
}
