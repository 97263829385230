.Card {
    $root: &;

    position: relative;
    width: 100%;

    background-color: $color-brand-white;
    border: rem(1) solid $color-brand-lightgrey-light;
    border-radius: vr(0.5);
    border-top: vr(0.5) solid $color-brand-darkgrey-light;
    padding: vr(1.75);
    height: 100%;
    transition: box-shadow 0.1s ease;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba($color-brand-darkgrey-pale, 0.05),
                0 2px 2px rgba($color-brand-darkgrey-pale, 0.05),
                0 4px 4px rgba($color-brand-darkgrey-pale, 0.05),
                0 8px 8px rgba($color-brand-darkgrey-pale, 0.05),
                0 16px 16px rgba($color-brand-darkgrey-pale, 0.05);

    display: flex;
    flex-direction: column;

    &:hover,
    &:active {
        box-shadow: 0 1px 1px rgba($color-brand-darkgrey-pale, 0.1),
                    0 2px 2px rgba($color-brand-darkgrey-pale, 0.1),
                    0 4px 4px rgba($color-brand-darkgrey-pale, 0.1),
                    0 8px 8px rgba($color-brand-darkgrey-pale, 0.1),
                    0 16px 16px rgba($color-brand-darkgrey-pale, 0.1);
    }

    &:focus-within {
        box-shadow: 0 1px 1px rgba($color-brand-darkgrey-pale, 0.1),
                    0 2px 2px rgba($color-brand-darkgrey-pale, 0.1),
                    0 4px 4px rgba($color-brand-darkgrey-pale, 0.1),
                    0 8px 8px rgba($color-brand-darkgrey-pale, 0.1),
                    0 16px 16px rgba($color-brand-darkgrey-pale, 0.1);
    }

    &:not(.-hover-off):hover,
    &:not(.-hover-off):active {
        background-color: rgba($color-brand-darkgrey-light, 0.05);
    }

    &:not(.-hover-off):focus-within {
        background-color: rgba($color-brand-darkgrey-light, 0.05);
    }

    &__hd {

    }

    &__bd {

        ~ #{$root}__ft {
            border-top: rem(1) solid $color-brand-lightgrey-light;
            padding-top: vr(1.75);
        }
    }

    &__ft {
        margin-top: auto;
    }

    &__bd,
    &__ft {

    }

    &__hd,
    &__bd {

        &:not(:last-child) {
            margin-bottom: vr(1.75);
        }
    }

    &__media {
        position: relative;
        overflow: hidden;
        height: 0;
        padding-bottom: 60%;

        &--square {
            padding-bottom: 100%;
        }
    }

    &__picture {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__img {
        object-fit: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__attributes {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        max-height: 100%;
        padding: {
            left: vr(1);
            right: vr(1);
            bottom: vr(1);
        }
        margin-bottom: vr(-0.5);
    }

    &__attribute {
        display: inline-block;
        position: relative;
        z-index: 1;
        margin: {
            right: vr(0.5);
            bottom: vr(0.5);
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__title {
        font-size: rem(20);
        line-height: vr(2);
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
    }

    &__link {
        display: block;
        position: static;

        &:hover,
        &:active,
        &:focus {

        }

        &:focus {
            outline: none;
            text-decoration: underline;
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &__subtitle {
        font-size: rem(20);
        font-weight: $font-weight-light;
        line-height: vr(2);
        margin-bottom: vr(1.25);
        color: $color-brand-darkgrey-light;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__summary {
        font-size: rem(16);
        line-height: vr(2);
    }

    &__metas {
        font-size: rem(16);
        line-height: vr(2);
        font-weight: $font-weight-light;
        color: $color-brand-darkgrey-light;
        margin-bottom: vr(1.25);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__meta {
        display: inline;

        &:after {
            content: ' | ';
        }

        &:last-child:after {
            content: none;
        }
    }

    &__metaLink {
        color: inherit;
        text-decoration: underline;
        position: relative;
        z-index: 1;

        &:hover,
        &:active,
        &:focus {
            color: $color-brand-nero;
        }

        &:focus {
            outline: none;
        }
    }

    &__action {
        color: $color-brand-palmgreen;
        font-weight: $font-weight-medium;
        position: relative;
        z-index: 1;

        &:not(:first-child) {
            margin-top: vr(1);
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }
    }

    &__type {
        background-color: rgba($color-brand-palmgreen, 0.2);
        color: $color-brand-palmgreen;
        //display: inline-block;
        display: none;
        padding: vr(0.25) vr(0.75);
        margin-bottom: vr(1);
        border-radius: vr(0.5);
        font-size: rem(12);
        line-height: rem(15);
        font-weight: $font-weight-medium;
    }

    &__date {
        font-size: rem(14);
        line-height: rem(15);
        font-weight: $font-weight-medium;
        color: $color-brand-darkgrey-pale;

        &:not(:first-child) {
            margin-top: vr(0.75);
        }
    }

    &__meta {

        &--keyValue {
            display: grid;
            grid-template-columns: vr(5) 1fr;
            grid-gap: rem(3);

            #{$root} {

                &__metaKey {
                    font-weight: $font-weight-medium;
                }
            }
        }
    }

    &__eventDate {
        font-weight: $font-weight-bold;
        background-color: rgba($color-brand-pacificblue,.1);
        padding: vr(0.5);
        text-align: center;
        color: $color-brand-pacificblue-light;
        position: absolute;
        right: vr(1);
        top: 0;
        line-height: 1;
        text-transform: uppercase;
        border-bottom-left-radius: vr(0.5);
        border-bottom-right-radius: vr(0.5);

        #{$root} {

            &__day {
                display: block;
                font-size: rem(20);
            }

            &__month {
                display: block;
                font-size: rem(15);
            }

            &__year {
                display: block;
                font-size: rem(14);
            }
        }
    }

    &.-size {

        &-sm {
            padding: vr(1);

            #{$root} {

                &__bd,
                &__ft {

                    &:not(:first-child) {
                        margin-top: vr(1);
                    }
                }

                &__hd,
                &__bd {

                    &:not(:last-child) {
                        margin-bottom: vr(1);
                    }
                }

                &__title {
                    font-size: rem(18);
                    line-height: vr(1.75);
                }

                &__description {
                    font-size: rem(14);
                    line-height: vr(1.5);

                    &:not(:first-child) {
                        margin-top: vr(0.25);
                    }
                }

                &__summary {

                }

                &__meta {
                    font-size: rem(14);
                    line-height: vr(1.5);
                }
            }
        }

        &-xs {
            padding: vr(1);
            border-color: rgba($color-brand-darkgrey-light, 0.25);
            border-top-width: rem(1);

            &:not(.-hover-off):hover,
            &:not(.-hover-off):active {
                border-color: rgba($color-brand-darkgrey-light, 0.5);
                background-color: rgba($color-brand-darkgrey-light, 0.05);
            }

            &:not(.-hover-off):focus-within {
                border-color: rgba($color-brand-darkgrey-light, 0.5);
                background-color: rgba($color-brand-darkgrey-light, 0.05);
            }

            #{$root} {

                &__bd {

                    ~ #{$root}__ft {
                        padding-top: vr(0.75);
                    }
                }

                &__hd,
                &__bd {

                    &:not(:last-child) {
                        margin-bottom: vr(0.75);
                    }
                }

                &__title {
                    font-size: rem(16);
                    line-height: vr(1.5);
                }

                &__description {
                    font-size: rem(14);
                    line-height: vr(1.5);

                    &:not(:first-child) {
                        margin-top: vr(0.25);
                    }
                }

                &__summary {

                }

                &__meta {
                    font-size: rem(14);
                    line-height: vr(1.5);
                }
            }
        }
    }

    &.is-highlight-document {
        border-color: rgba($color-brand-heritagered, 0.5);
        border-width: rem(2);

        &:not(.-hover-off):hover,
        &:not(.-hover-off):active {
            border-color: $color-brand-heritagered;
            background-color: rgba($color-brand-heritagered, 0.05);
        }

        &:not(.-hover-off):focus-within {
            border-color: $color-brand-heritagered;
            background-color: rgba($color-brand-heritagered, 0.05);
        }
    }

    &--resourceset,
    &--document,
    &--video {
        border-top-color: $color-brand-heritagered;

        &:not(.-hover-off):hover,
        &:not(.-hover-off):active {
            background-color: rgba($color-brand-heritagered, 0.05);
        }

        &:not(.-hover-off):focus-within {
            background-color: rgba($color-brand-heritagered, 0.05);
        }

        #{$root} {

            &__type {
                background-color: rgba($color-brand-heritagered, 0.2);
                color: $color-brand-heritagered;
            }

            &__action {
                color: $color-brand-heritagered;
            }
        }
    }

    &--event {
        border-top-color: $color-brand-pacificblue;

        &:not(.-hover-off):hover,
        &:not(.-hover-off):active {
            background-color: rgba($color-brand-pacificblue, 0.05);
        }

        &:not(.-hover-off):focus-within {
            background-color: rgba($color-brand-pacificblue, 0.05);
        }

        #{$root} {

            &__hd {
                padding-right: vr(4);
            }

            &__type {
                background-color: rgba($color-brand-pacificblue, 0.2);
                color: $color-brand-pacificblue;
            }

            &__action {
                color: $color-brand-pacificblue;
            }
        }
    }

    &--article,
    &--blogpost,
    &--story {
        border-top-color: $color-brand-palmgreen;

        &:not(.-hover-off):hover,
        &:not(.-hover-off):active {
            background-color: rgba($color-brand-palmgreen, 0.05);
        }

        &:not(.-hover-off):focus-within {
            background-color: rgba($color-brand-palmgreen, 0.05);
        }

        #{$root} {

            &__type {
                background-color: rgba($color-brand-palmgreen, 0.2);
                color: $color-brand-palmgreen;
            }

            &__action {
                color: $color-brand-palmgreen;
            }
        }
    }
}
