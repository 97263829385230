@use "sass:math";
$cmscontent-margin-unit: vr(2.25) !default;
$cmscontent-container-unit: vr(1.5) !default;

.s-cmsContent {
    $root: &;

    word-wrap: break-word;
    color: currentColor;
    font-size: rem(18);
    line-height: $cmscontent-margin-unit;

    // ==============================
    // Headers
    // ==============================
    h1, h2, h3,
    h4, h5, h6 {
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        color: currentColor;
        margin-bottom: $cmscontent-margin-unit;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    h1 {
        font-size: rem(48);
        line-height: vr(4.5);
        margin-top: $cmscontent-margin-unit * 2.5;

        @include mq-from(cmscontent-bp1) {
            font-size: rem(48);
            line-height: vr(4.5);
        }

        @include mq-from(cmscontent-bp2) {
            font-size: rem(48);
            line-height: vr(4.5);
        }
    }

    h2 {
        font-size: rem(42);
        line-height: vr(4);
        margin-top: $cmscontent-margin-unit * 2.25;

        @include mq-from(cmscontent-bp1) {
            font-size: rem(42);
            line-height: vr(4);
        }
    }

    h3 {
        color: currentColor;
        font-size: rem(36);
        line-height: vr(3.5);
        margin-top: $cmscontent-margin-unit * 2;
    }

    h4 {
        font-size: rem(30);
        line-height: vr(3);
        margin-top: $cmscontent-margin-unit * 2;
    }

    h5 {
        font-size: rem(27);
        line-height: vr(2.75);
        font-weight: $font-weight-medium;
        margin-top: $cmscontent-margin-unit * 1.75;
    }

    h6 {
        font-size: rem(24);
        line-height: vr(2.5);
        font-weight: $font-weight-medium;
        margin-top: $cmscontent-margin-unit * 1.75;
    }

    // ==============================
    // Links
    // ==============================
    a:not(.Button) {
        color: $color-brand-warmorange-dark;
        font-weight: inherit;
        text-decoration: underline;
        cursor: pointer;
        transition: all 0.2s ease;

        &:visited {
            color: $color-brand-warmorange-dark;
        }

        &:hover {
            color: $color-brand-warmorange;
            text-decoration: none;
        }

        &:active {
            color: $color-brand-warmorange;
        }

        &:focus {
            background-color: transparent;
            color: $color-brand-warmorange;
            text-decoration: none;
            //noinspection CssInvalidPropertyValue
            outline: rem(2) solid $color-brand-warmorange;
        }

        &[rel~=help] {
            cursor: help;
        }

        img {
            border: none;
        }
    }

    // ==============================
    // Paragraphs
    // ==============================
    p {
        margin-bottom: $cmscontent-margin-unit;

        &:last-child {
            margin-bottom: 0;
        }

        &.Lede {
            font-size: rem(20);
            line-height: vr(2.25);
            font-weight: $font-weight-normal;
        }
    }

    // ==============================
    // Lists
    // ==============================
    ul, ol, dl {
        margin-bottom: $cmscontent-margin-unit;

        &:last-child {
            margin-bottom: 0;
        }

        ul, ol, dl {
            margin-bottom: 0;
            margin-top: math.div($cmscontent-margin-unit, 4);
        }

        ul, ol {
            margin-left: $cmscontent-margin-unit;
        }
    }

    ul {
        margin-left: $cmscontent-margin-unit * 1.25;
        list-style-position: outside;
        list-style-type: none;

        li {

            &:before {
                color: currentColor;
                font-weight: $font-weight-semi-bold;
                content: '\2022';
                left: vr(-1.5);
                position: absolute;
                top: 0;
            }
        }
    }

    ol {
        margin-left: $cmscontent-margin-unit * 1.25;
        list-style-type: decimal;
        list-style-position: outside;
        counter-reset: list;

        li {
            padding-left: math.div($cmscontent-margin-unit, 3);
        }
    }

    li {
        position: relative;
        margin-bottom: math.div($cmscontent-margin-unit, 3);
    }

    dl {
        margin-left: math.div($cmscontent-margin-unit, 1.25);
    }

    dt {
        margin-bottom: math.div($cmscontent-margin-unit, 3);
        font-weight: $font-weight-bold;
    }

    dd {
        margin-bottom: math.div($cmscontent-margin-unit, 1.5);

        &:last-child {
            margin-bottom: 0;
        }
    }

    // ==============================
    // Images & Media
    // ==============================
    img {
        display: block;
        height: auto;
        min-width: 100%;
        max-width: 100%;
        margin-bottom: $cmscontent-margin-unit;

        @include mq-from(cmscontent-inlineimg-bp1) {
            min-width: auto;
        }
    }

    .captionImage,
    .embed {
        overflow: hidden;
        height: auto;
        width: auto;
        max-width: 100%;
        margin-bottom: $cmscontent-margin-unit;

        &:last-child {
            margin-bottom: 0;
        }

        @include mq-before(cmscontent-inlineimg-bp1) {
            width: 100% !important; // overriding WYSIWYG inline style
        }

        @include mq-from(cmscontent-inlineimg-bp1) {
            overflow: auto;
            width: auto;
        }

        > .caption {
            font-size: rem(16);
            font-style: italic;
            line-height: vr(1.75);
            text-align: center;
            margin-top: vr(1.25);
            padding: {
                left: math.div($cmscontent-margin-unit, 4);
                right: math.div($cmscontent-margin-unit, 4);
            }
        }

        &.left,
        &.leftAlone {

            > .caption {

                @include mq-from(cmscontent-inlineimg-bp1) {
                    text-align: left;
                }
            }
        }

        &.right,
        &.rightAlone {

            > .caption {

                @include mq-from(cmscontent-inlineimg-bp1) {
                    text-align: right;
                }
            }
        }

        &.center {

            > .caption {

                @include mq-from(cmscontent-inlineimg-bp1) {
                    text-align: center;
                }
            }
        }

        > img {
            margin-bottom: 0;

            &.left,
            &.leftAlone,
            &.right,
            &.rightAlone,
            &.center {

                @include mq-from(cmscontent-inlineimg-bp1) {
                    float: none;
                    margin: {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }

        > embed,
        > iframe,
        > object,
        > video {
            width: 100%;
            max-width: 100%;
        }
    }

    img,
    .captionImage,
    .embed {

        &.left {

            @include mq-from(cmscontent-inlineimg-bp1) {
                float: left;
                margin-right: $cmscontent-margin-unit;
            }
        }

        &.leftAlone {

            @include mq-from(cmscontent-inlineimg-bp1) {
                margin-right: auto;
            }
        }

        &.right {

            @include mq-from(cmscontent-inlineimg-bp1) {
                float: right;
                margin-left: $cmscontent-margin-unit;
            }
        }

        &.rightAlone {

            @include mq-from(cmscontent-inlineimg-bp1) {
                margin-left: auto;
            }
        }

        &.center {

            @include mq-from(cmscontent-inlineimg-bp1) {
                margin: {
                    left: auto;
                    right: auto;
                }
            }
        }
    }

    // ==============================
    // Blockquotes
    // ==============================
    blockquote {
        @include text-font-smoothing;
        border-left: math.div($cmscontent-container-unit, 2) solid $color-brand-warmorange;
        color: $color-brand-warmorange;
        font-size: rem(20);
        font-weight: $font-weight-bold;
        line-height: vr(2.25);
        margin: {
            left: math.div($cmscontent-container-unit, 1.5) * -1;
            bottom: $cmscontent-margin-unit;
        }
        padding: {
            bottom: math.div($cmscontent-margin-unit, 2);
            left: math.div($cmscontent-margin-unit, 2);
            top: math.div($cmscontent-margin-unit, 2);
        }

        &:last-child {
            margin-bottom: 0;
        }

        @include mq-from(blockquote-bp1) {
            margin-left: math.div($cmscontent-container-unit, 2) * -1;
        }
    }

    // ==============================
    // HR
    // ==============================
    hr {
        border: {
            bottom: 0;
            left: 0;
            right: 0;
            top: rem(1) solid $color-brand-lightgrey-light;
        }
        clear: both;
        height: 0;
        margin: {
            top: $cmscontent-margin-unit - rem(1);
            bottom: $cmscontent-margin-unit;
        }
    }

    // ==============================
    // Abbreviations
    // ==============================
    abbr,
    acronym {
        text-transform: uppercase;
        font-size: 90%;
        border-bottom: rem(1) dotted $color-brand-lightgrey-light;
        cursor: help;
    }
    abbr {
        text-transform: none;
    }

    // ==============================
    // Highlights
    // ==============================
    //::selection {
    //    background: rgba($color-brand-green-dark, 0.8);
    //    color: $color-brand-ghost;
    //    text-shadow: none;
    //}

    // ==============================
    // Table
    // ==============================
    table {
        border-collapse: collapse;
        margin-bottom: $cmscontent-margin-unit;

        //@include mq-before(table-bp1) {
        //    width: 100% !important;
        //    float: none !important;
        //    margin: {
        //        left: 0 !important;
        //        right: 0 !important;
        //    }
        //}

        &:last-child {
            margin-bottom: 0;
        }

        //&[style*='float: right;'] {
        //    @include mq-from(table-bp1) {
        //        margin-left: $cmscontent-margin-unit;
        //    }
        //}
        //
        //&[style*='float: left;'] {
        //    @include mq-from(table-bp1) {
        //        margin-right: $cmscontent-margin-unit;
        //    }
        //}

        &[style*='margin-left: auto; margin-right: auto;'] {

        }

        > caption {
            background-color: transparent;
            font-weight: $font-weight-normal;
            padding: {
                top: math.div($cmscontent-margin-unit, 2);
                bottom: math.div($cmscontent-margin-unit, 2);
                left: $cmscontent-margin-unit * 0.75;
                right: $cmscontent-margin-unit * 0.75;
            }
            border-bottom: rem(2) solid currentColor;
        }

        th, td {
            padding: {
                top: math.div($cmscontent-margin-unit, 2);
                bottom: math.div($cmscontent-margin-unit, 2);
                left: $cmscontent-margin-unit * 0.75;
                right: $cmscontent-margin-unit * 0.75;
            }
            font-size: rem(16);
            line-height: $cmscontent-margin-unit * 0.75;
            border: {
                right: rem(2) solid currentColor;
                bottom: rem(2) solid currentColor;
            }
            background-color: transparent;

            &:last-child {
                border-right: 0;
            }
        }

        tr:last-child {
            > td,
            > th {
                border-bottom: 0;
            }
        }

        &.-colheaders-on,
        thead,
        tfoot {

            tr:first-child {

                > td,
                > th {
                    background-color: transparent;
                    font-weight: $font-weight-normal;
                }
            }
        }

        &.-rowheaders-on {

            td:first-child,
            th:first-child {
                background-color: transparent;
                font-weight: $font-weight-normal;
            }
        }
    }

    //@include mq-before(cmscontent-table-bp1) {
    //
    //
    //    table:not(.-responsive-off) {
    //        display: block;
    //
    //        thead, tfoot, tbody, th, td, tr, caption {
    //            display: block;
    //        }
    //
    //        /* Hide table headers (but not display: none;, for accessibility) */
    //        thead tr {
    //            position: absolute;
    //            top: rem(-999999);
    //            left: rem(-999999);
    //        }
    //
    //        td {
    //            /* Behave  like a "row" */
    //            border: none;
    //            border-bottom: rem(2) solid currentColor;
    //            position: relative;
    //            padding-left: 50%;
    //        }
    //
    //        td:before {
    //            /* Now like a table header */
    //            position: absolute;
    //            /* Top/left values mimic padding */
    //            top: $cmscontent-margin-unit / 2;
    //            left: $cmscontent-margin-unit * 0.75;
    //            padding-right: $cmscontent-margin-unit * 0.75;
    //            width: calc(50% - #{$cmscontent-margin-unit * 0.75});
    //            white-space: nowrap;
    //        }
    //
    //        &.-rowheaders-on {
    //
    //            td:first-child,
    //            th:first-child {
    //                padding-left: $cmscontent-margin-unit * 0.75;
    //            }
    //        }
    //
    //        /*
    //        Label the data
    //        */
    //        //td:nth-of-type(2):before { content: "Australia"; }
    //    }
    //}

    // ==============================
    // Miscellaneous
    // ==============================
    em,
    i {
        font-style: italic;
        line-height: inherit;
    }
    strong,
    b {
        font-weight: $font-weight-bold;
        line-height: inherit;

        a {
            font-weight: inherit;
        }
    }
    //mark {
    //    background-color: $color-mark-background;
    //    color: $color-mark;
    //}

    address {
        font-style: normal;
        line-height: inherit;
        margin-bottom: $cmscontent-margin-unit;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .large {
        font-size: rem(20);
        line-height: vr(2.25);
    }

    .small {
        font-size: rem(16);
        line-height: vr(1.75);
    }

    var {
        font-style: italic;
    }

    .text-left {
        text-align: left;
    }
    .text-right {
        text-align: right;
    }
    .text-center {
        text-align: center;
    }
    .text-justify {
        text-align: justify;
    }
}
