$site-gutters: map-get($site-container, gutters);

@mixin sitecontainer {
    width: 100%;
    max-width: map-get($site-container, max-width);
    margin: {
        left: auto;
        right: auto;
    }
}

@mixin sitepad {
    padding: {
        left: nth($site-gutters, 1);
        right: nth($site-gutters, 1);
    }

    @include mq-from(container-bp1) {
        padding: {
            left: nth($site-gutters, 2);
            right: nth($site-gutters, 2);
        }
    }

    @include mq-from(container-bp2) {
        padding: {
            left: nth($site-gutters, 3);
            right: nth($site-gutters, 3);
        }
    }
}
