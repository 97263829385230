.ElementImage {
    $root: &;

    position: relative;

    &__media {
        display: block;
        width: 100%;
        height: auto;
    }

    &__detail {
        position: absolute;
        left: vr(1.5);
        bottom: vr(1.5);
        max-width: calc(100% - #{vr(3)});
        background-color: rgba($color-brand-ghost, 0.75);
        color: $color-brand-nero;
        font-weight: $font-weight-normal;
        padding: vr(1) vr(1.5);
    }

    &__caption {
        display: block;
        max-width: rem(300);
        font-weight: $font-weight-medium;
        font-size: rem(18);
        line-height: vr(1.75);

        + #{$root}__attribution {
            margin-top: vr(0.5);
        }
    }

    &__attribution {
        display: block;
        max-width: rem(300);
        font-size: rem(14);
        line-height: vr(1.5);
    }
}
