.ElementClientsGrid {
    $root: &;

    &__items {
        display: grid;
        grid-gap: vr(4);
        grid-template-columns: repeat(auto-fill, minmax(#{rem(150)}, 1fr));

        @supports (width: unquote('min(#{rem(150)}, 100%)')) {
            grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(150)}, 100%)'), 1fr));
        }
        text-align: center;
    }

    &__item {
        height: vr(5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__img {
        max-height: 100%;
        width: auto;
    }
}
