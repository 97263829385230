.o-Icon {
    $root: &;

    display: inline-block;
    height: 100%;
    width: auto;
    position: relative;

    &__link {
        display: inline-block;
        height: 100%;
        color: inherit;
        position: relative;

        &:hover,
        &:active,
        &:focus {
            color: inherit;
        }

        //&:focus {
        //    outline: {
        //        width: rem(2);
        //        style: solid;
        //        color: currentColor;
        //        offset: rem(4);
        //    }
        //}
    }

    &__canvas {
        display: none;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            display: inline-block;
            height: 100%;
            visibility: hidden;

            + #{$root}__svg {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &__svg {
        display: block;
        height: 100%;
        width: auto;

        &.-fill-curr {
            fill: currentColor;
        }
    }

    &__img {
        display: block;
        height: 100%;
        width: auto;
    }

    &__fill {
        fill: currentColor;
    }

    &.-theme {

        &-current {

            #{$root} {

                &__svg,
                &__fill {
                    fill: currentColor;
                }
            }
        }
    }

    &.-fill-width {
        height: auto;
        width: 100%;
        display: block;

        #{$root} {

            &__link {
                display: block;
            }

            &__link,
            &__canvas,
            &__svg,
            &__img {
                height: auto;
                width: 100%;
            }
        }
    }
}
