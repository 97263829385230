$color-info: 				$color-brand-blue-true;
$color-info-background:		$color-brand-blue-harmony;
$color-info-border:			$color-brand-blue-true;
$color-success: 			$color-brand-success;
$color-success-background:	tint($color-success, 90%) !default;
$color-success-border:		$color-success !default;
$color-warning: 			$color-brand-warning;
$color-warning-background:	tint($color-warning, 90%) !default;
$color-warning-border:		$color-warning !default;
$color-error: 				$color-brand-danger;
$color-error-background:	tint($color-error, 90%) !default;
$color-error-border:		$color-error !default;

:not(.login-form) {

    .message,
    .Message {
        display: block;
        background-color: $color-info-background;
        background-position: vr(1) vr(1);
        background-size: vr(1.5);
        background-repeat: no-repeat;
        background-image: #{assetspath('svgs/alerts/info.svg')};
        color: $color-info;
        padding: {
            top: vr(1);
            bottom: vr(1);
            left: vr(3.5);
            right: vr(2);
        }
        margin-bottom: vr(2);
        border: {
            width: rem(1);
            style: solid;
            color: $color-info-border;
        }

        font-size: rem(13);
        line-height: vr(1.5);

        > p {
            margin-bottom: vr(0.5);

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--info,
        &.info {
            background-color: $color-info-background;
            border-color: $color-info-border;
            color: $color-info;
            background-image: #{assetspath('svgs/alerts/info.svg')};
        }

        &--success,
        &.good,
        &.success {
            background-color:$color-success-background;
            border-color: $color-success-border;
            color: $color-success;
            background-image: #{assetspath('svgs/alerts/success.svg')};
        }

        &--warning,
        &.warning {
            background-color:$color-warning-background;
            border-color: $color-warning-border;
            color: $color-warning;
            background-image: #{assetspath('svgs/alerts/warning.svg')};
        }

        &--error,
        &.bad,
        &.error,
        &.required {
            background-color:$color-error-background;
            border-color: $color-error-border;
            color: $color-error;
            background-image: #{assetspath('svgs/alerts/error.svg')};
        }

        a {
            color: inherit;
            text-decoration: underline;

            &:hover,
            &:active {
                text-decoration: none;
            }
        }
    }
}
