.Hero {
    $root: &;

    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: z(hero);
    background: {
        image: none;
        color: transparent;
        size: cover;
        repeat: no-repeat;
        attachment: scroll;
        position: 50% 50%;
    }

    &__overlay {
        position: absolute;
        z-index: z(hero-overlay);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    &__container {
        position: relative;
        z-index: z(hero-container);
        display: grid;
        grid-template-rows: auto min-content;
        grid-template-columns: 1fr;
        text-align: center;
        align-items: center;
        justify-items: center;
        min-height: rem(400);

        @include mq-from(hero-bp2) {
            min-height: rem(460);
        }
    }

    &__content {
        position: relative;
        z-index: z(hero-content);
        width: 100%;
        max-width: $content-max-width;
        padding: {
            top: vr(7.5);
            bottom: vr(7.5);
        }

        @include mq-from(hero-bp2) {
            padding: {
                top: vr(11);
                bottom: vr(11);
            }
        }
    }

    &__title {
        font-size: rem(60);
        line-height: vr(5.5);
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        width: 100%;
        margin-bottom: vr(2.5);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__titleLink {

        &:hover,&:active,&:focus {
            text-decoration: underline;
        }
    }

    &__subtitle {
        font-size: rem(26);
        line-height: vr(2.75);
        font-weight: $font-weight-medium;
        margin-bottom: vr(2.5);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__cta {

    }

    &__button {

    }

    &__metabar {
        position: relative;
        z-index: z(hero-metabar);
        min-height: vr(7.5);
        display: grid;
        align-items: center;
        justify-items: center;
        text-align: center;
        width: 100%;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            height: rem(1);
            opacity: 0.25;
            background-color: currentColor;
        }
    }

    &__meta,
    &__crumbs {
        max-width: $content-max-width;
        margin-bottom: vr(1.5);

        &:first-child {
            margin-top: vr(1.5);
        }
    }

    &.-height {

        &-banner {

            #{$root}__container {
                min-height: 0;
                padding: {
                    top: vr(1);
                    bottom: vr(1);
                }
            }

            #{$root}__content {
                padding: {
                    top: 0;
                    bottom: 0;
                }
            }

            #{$root}__title {
                font-size: rem(30);
                line-height: vr(3);
            }
        }

        &-sm {

            #{$root}__container {
                min-height: rem(180);

                @include mq-from(hero-bp2) {
                    min-height: rem(220);
                }
            }

            #{$root}__content {
                padding: {
                    top: vr(4);
                    bottom: vr(4);
                }

                @include mq-from(hero-bp2) {
                    padding: {
                        top: vr(6);
                        bottom: vr(6);
                    }
                }
            }

            #{$root}__meta,
            #{$root}__crumbs {
                margin-bottom: vr(1);

                &:first-child {
                    margin-top: vr(1);
                }
            }

            #{$root}__metabar {
                min-height: vr(5);
            }
        }

        &-md {
            // default
        }

        &-lg {

            #{$root}__container {
                min-height: rem(450);

                @include mq-from(hero-bp2) {
                    height: rem(560);
                }

                @include mq-from(hero-bp5) {
                    height: rem(647);
                }
            }
        }
    }

    &.-flourish-on {

        &:before {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            background: transparent  #{assetspath('images/overlay_03.png')} scroll no-repeat top right;
            background-size: contain;
            opacity: 0.25;
        }
    }

    &.-align {

        &-horizontal {

            &-left {

                #{$root}__container,
                #{$root}__metabar {
                    text-align: left;
                    justify-items: start;
                }
            }

            &-center {
                // default
            }

            &-right {

                #{$root}__container,
                #{$root}__metabar {
                    text-align: right;
                    justify-items: end;
                }
            }
        }

        &-vertical {

            &-top {

                #{$root}__container {
                    align-items: start;
                }
            }

            &-middle {
                // default
            }

            &-bottom {

                #{$root}__container {
                    align-items: end;
                }
            }
        }
    }

    &__overlay {

        &--5 {opacity: 0.05;}
        &--10 {opacity: 0.10;}
        &--15 {opacity: 0.15;}
        &--20 {opacity: 0.20;}
        &--25 {opacity: 0.25;}
        &--30 {opacity: 0.30;}
        &--35 {opacity: 0.35;}
        &--40 {opacity: 0.40;}
        &--45 {opacity: 0.45;}
        &--50 {opacity: 0.50;}
        &--55 {opacity: 0.55;}
        &--60 {opacity: 0.60;}
        &--65 {opacity: 0.65;}
        &--70 {opacity: 0.70;}
        &--75 {opacity: 0.75;}
        &--80 {opacity: 0.80;}
        &--85 {opacity: 0.85;}
        &--90 {opacity: 0.90;}
        &--95 {opacity: 0.95;}
        &--100 {opacity: 1;}
    }
}

.-theme {

    &-default,
    &-white {

        &.-t-bgcolor,
        .-t-bgcolor {

            &-primary {
                background-color: $color-brand-white;
            }

            &-text {
                background-color: $color-brand-nero;
            }
        }

        &.-t-color,
        .-t-color {

            &-primary {
                color: $color-brand-white;
            }

            &-text {
                color: $color-brand-nero;
            }
        }

        .Button.-t-button {
            border-color: $color-brand-warmorange;
            background-color: $color-brand-warmorange;
            color: $color-brand-white;

            //&:not(.is-loading) {
            //    color: $color-brand-warmorange;
            //}

            &:not([disabled]):not(.is-loading):not(.Button--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-warmorange-dark;
                    border-color: $color-brand-warmorange-dark;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-warmorange;
                }
            }
        }
    }

    &-black {

        &.-t-bgcolor,
        .-t-bgcolor {

            &-primary {
                background-color: $color-brand-nero;
            }

            &-text {
                background-color: $color-brand-white;
            }
        }

        &.-t-color,
        .-t-color {

            &-primary {
                color: $color-brand-nero;
            }

            &-text {
                color: $color-brand-white;
            }
        }

        .Button.-t-button {
            border-color: $color-brand-warmorange;
            background-color: $color-brand-warmorange;
            color: $color-brand-white;

            //&:not(.is-loading) {
            //    color: $color-brand-warmorange;
            //}

            &:not([disabled]):not(.is-loading):not(.Button--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-warmorange-dark;
                    border-color: $color-brand-warmorange-dark;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-warmorange;
                }
            }
        }
    }

    &-orange {

        &.-t-bgcolor,
        .-t-bgcolor {

            &-primary {
                background-color: $color-brand-warmorange;
            }

            &-text {
                background-color: $color-brand-white;
            }
        }

        &.-t-color,
        .-t-color {

            &-primary {
                color: $color-brand-warmorange;
            }

            &-text {
                color: $color-brand-white;
            }
        }

        .Button.-t-button {
            border-color: $color-brand-white;
            background-color: $color-brand-white;
            color: $color-brand-warmorange;

            //&:not(.is-loading) {
            //    color: $color-brand-warmorange;
            //}

            &:not([disabled]):not(.is-loading):not(.Button--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-warmorange-dark;
                    border-color: $color-brand-warmorange-dark;
                    color: $color-brand-white;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-white;
                }
            }
        }
    }

    &-blue {

        &.-t-bgcolor,
        .-t-bgcolor {

            &-primary {
                background-color: $color-brand-pacificblue;
            }

            &-text {
                background-color: $color-brand-white;
            }
        }

        &.-t-color,
        .-t-color {

            &-primary {
                color: $color-brand-pacificblue;
            }

            &-text {
                color: $color-brand-white;
            }
        }

        .Button.-t-button {
            border-color: $color-brand-warmorange;
            background-color: $color-brand-warmorange;
            color: $color-brand-white;

            //&:not(.is-loading) {
            //    color: $color-brand-warmorange;
            //}

            &:not([disabled]):not(.is-loading):not(.Button--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-warmorange-dark;
                    border-color: $color-brand-warmorange-dark;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-warmorange;
                }
            }
        }
    }

    &-green {

        &.-t-bgcolor,
        .-t-bgcolor {

            &-primary {
                background-color: $color-brand-palmgreen;
            }

            &-text {
                background-color: $color-brand-white;
            }
        }

        &.-t-color,
        .-t-color {

            &-primary {
                color: $color-brand-palmgreen;
            }

            &-text {
                color: $color-brand-white;
            }
        }

        .Button.-t-button {
            border-color: $color-brand-white;
            background-color: $color-brand-white;
            color: $color-brand-palmgreen;

            //&:not(.is-loading) {
            //    color: $color-brand-warmorange;
            //}

            &:not([disabled]):not(.is-loading):not(.Button--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-palmgreen-dark;
                    border-color: $color-brand-palmgreen-dark;
                    color: $color-brand-white;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-white;
                }
            }
        }
    }

    &-red {

        &.-t-bgcolor,
        .-t-bgcolor {

            &-primary {
                background-color: $color-brand-heritagered;
            }

            &-text {
                background-color: $color-brand-white;
            }
        }

        &.-t-color,
        .-t-color {

            &-primary {
                color: $color-brand-heritagered;
            }

            &-text {
                color: $color-brand-white;
            }
        }

        .Button.-t-button {
            border-color: $color-brand-white;
            background-color: $color-brand-white;
            color: $color-brand-heritagered;

            //&:not(.is-loading) {
            //    color: $color-brand-warmorange;
            //}

            &:not([disabled]):not(.is-loading):not(.Button--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-heritagered-dark;
                    border-color: $color-brand-heritagered-dark;
                    color: $color-brand-white;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-white;
                }
            }
        }
    }

    &-shell {

        &.-t-bgcolor,
        .-t-bgcolor {

            &-primary {
                background-color: $color-brand-conchshell;
            }

            &-text {
                background-color: $color-brand-darkgrey;
            }
        }

        &.-t-color,
        .-t-color {

            &-primary {
                color: $color-brand-conchshell;
            }

            &-text {
                color: $color-brand-darkgrey;
            }
        }

        .Button.-t-button {
            border-color: $color-brand-darkgrey;
            background-color: $color-brand-darkgrey;
            color: $color-brand-conchshell;

            //&:not(.is-loading) {
            //    color: $color-brand-warmorange;
            //}

            &:not([disabled]):not(.is-loading):not(.Button--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-conchshell-dark;
                    border-color: $color-brand-conchshell-dark;
                    color: $color-brand-darkgrey;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-darkgrey;
                }
            }
        }
    }

    &-darkgrey {

        &.-t-bgcolor,
        .-t-bgcolor {

            &-primary {
                background-color: $color-brand-darkgrey;
            }

            &-text {
                background-color: $color-brand-white;
            }
        }

        &.-t-color,
        .-t-color {

            &-primary {
                color: $color-brand-darkgrey;
            }

            &-text {
                color: $color-brand-white;
            }
        }

        .Button.-t-button {
            border-color: $color-brand-warmorange;
            background-color: $color-brand-warmorange;
            color: $color-brand-white;

            //&:not(.is-loading) {
            //    color: $color-brand-warmorange;
            //}

            &:not([disabled]):not(.is-loading):not(.Button--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-warmorange-dark;
                    border-color: $color-brand-warmorange-dark;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-warmorange;
                }
            }
        }
    }
}
