.o-SideBlock {
    $root: &;

    &__container {
        position: relative;
    }

    &__hd,
    &__bd {
        position: relative;
    }

    &__hd {
        max-width: $content-max-width;

        border-bottom: rem(1) solid $color-brand-lightgrey;
        padding: {
            left: vr(1);
            right: vr(1);
            bottom: vr(1);
        }

        + #{$root}__bd {
            margin-top: vr(2.5);
        }
    }

    &__title {
        font-size: rem(16);
        line-height: vr(1.75);
        font-weight: $font-weight-bold;
        color: $color-brand-darkgrey-dark;

        + #{$root}__subtitle {
            margin-top: vr(0.5);
        }
    }

    &__titleLink {
        text-decoration: none;

        &:hover,&:active,&:focus {
            text-decoration: underline;
        }
    }

    &__subtitle {
        font-size: rem(14);
        font-weight: $font-weight-medium;
        color: $color-brand-darkgrey-pale;
        line-height: vr(1.5);
    }

    &__cta {
        margin: {
            top: vr(-1.5);
            bottom: vr(-1.5);
        }
    }

    &__button {

    }

    &__element {
        margin-bottom: vr(2.5);

        &:last-child {
            margin-bottom: 0;
        }
    }

    // MODIFIERS

    /*
    &.-pad {

        &-bottom-off {

            #{$root}__container {
                margin-bottom: vr(-1);
            }
        }
    }
    */

    &.-border {

        &-bottom-on {

            #{$root}__container {
                border-bottom: rem(1) solid $color-brand-lightgrey-light;
                padding-bottom: vr(2);
            }
        }
    }

    &:not(.-border-off) {
        padding: vr(1.5);
        border-radius: vr(0.5);
        border: rem(1) solid $color-brand-lightgrey-light;
    }

    &.-theme {

        &-white {

        }

        &-grey {
            background-color: $color-brand-lightgrey-pale;
            border-color: $color-brand-lightgrey-light;
        }

        &-lightshell {
            background-color: $color-brand-conchshell-pale;
            border-color: $color-brand-conchshell;
        }

        &-shell {
            background-color: $color-brand-conchshell;
            border-color: $color-brand-conchshell-pale;
        }
    }
}
