.MetaItems {
    $root: &;

    &__title {
        font-size: rem(12);
        line-height: vr(1.5);
        font-weight: $font-weight-medium;
        color: $color-brand-darkgrey-light;
        font-family: $font-family-headings;
        text-transform: uppercase;
        margin-bottom: vr(0.25);

        &:not(:first-child) {
            margin-top: vr(1);
        }
    }

    &__value {
        font-size: rem(14);
        line-height: vr(1.75);
        color: $color-brand-darkgrey;
    }

    &__list {

    }

    &__listItem {
        display: inline-block;

        //&:not(:last-child) {
        //
        //    &:after {
        //        content: ', '
        //    }
        //}
    }

    &__listText,
    &__listLink {
        display: inline-block;
        padding: vr(0.25) vr(0.75);
        background-color: rgba($color-brand-palmgreen, 0.2);
        color: $color-brand-palmgreen;
        margin-bottom: vr(1);
        border-radius: vr(0.5);
        font-size: rem(12);
        line-height: rem(15);
        font-weight: $font-weight-medium;
    }

    &__listLink {

        &:hover,
        &:active,
        &:focus {
            background-color: $color-brand-palmgreen;
            color: $color-brand-white;
        }
    }



    &--resourceset,
    &--document,
    &--video {

        #{$root} {

            &__listText,
            &__listLink {
                background-color: rgba($color-brand-heritagered, 0.2);
                color: $color-brand-heritagered;
            }

            &__listLink {

                &:hover,
                &:active,
                &:focus {
                    background-color: $color-brand-heritagered;
                    color: $color-brand-white;
                }
            }
        }
    }

    &--event {

        #{$root} {

            &__listText,
            &__listLink {
                background-color: rgba($color-brand-pacificblue, 0.2);
                color: $color-brand-pacificblue;
            }

            &__listLink {

                &:hover,
                &:active,
                &:focus {
                    background-color: $color-brand-pacificblue;
                    color: $color-brand-white;
                }
            }
        }
    }

    &.-size-md {

        #{$root} {

            &__title {
                margin-bottom: vr(0.5);
            }

            &__listText,
            &__listLink {
                font-size: rem(14);
                padding: vr(0.5) vr(1);
            }
        }
    }
}
