.ElementTabbed {
    $root: &;

    &:not(.is-tabbed) #{$root} {

        &__tabs {
            margin-top: vr(4);
            border-left: rem(6) solid $color-brand-pacificblue;
            padding: {
                top: vr(0.75);
                bottom: vr(0.75);
                left: vr(1.75);
            };
        }

        &__tab {
            font-size: rem(18);
            line-height: vr(2.25);
            padding-top: vr(0.5);
            font-weight: $font-weight-bold;

            &:not(:last-child) {
                padding-bottom: vr(0.5);
            }
        }

        &__link {
            color: $color-brand-warmorange-dark;
            text-decoration: none;

            &:hover,
            &:active {
                color: $color-brand-warmorange;
                text-decoration: underline;
            }

            &:focus {
                color: $color-brand-warmorange;
                text-decoration: underline;
                background-color: transparent;
                outline: {
                    width: rem(3);
                    style: solid;
                    color: currentColor;
                    offset: 0;
                }
            }
        }

        &__panel {
            margin-top: vr(3);
        }

        &__title {
            font-family: $font-family-headings;
            font-weight: $font-weight-bold;
            font-size: rem(36);
            line-height: vr(3.5);
            margin-bottom: vr(2);
        }
    }

    &.is-tabbed #{$root} {

        &__tabs {
            display: flex;
            margin-bottom: rem(-2);
        }

        &__tab {
            display: inline-block;

            &:not(:first-child) {
                margin-left: rem(-2);
            }
        }

        &__link {
            display: block;
            font-weight: $font-weight-semi-bold;
            text-decoration: none;
            padding: rem(10) rem(20);
            border: rem(2) solid $color-brand-lightgrey-light;
            background-color: $color-brand-lightgrey-pale;
            height: 100%;

            &[aria-selected] {
                border-bottom-color: $color-brand-white;
                background: $color-brand-white;
            }

            &:not([aria-selected]) {

                &:hover,
                &:active {
                    background: $color-brand-lightgrey;
                }
            }

            &:focus {
                outline: {
                    width: rem(2);
                    style: solid;
                    color: currentColor;
                    offset: rem(-4);
                }
            }
        }

        &__panel {
            padding: vr(2);
            border: rem(2) solid $color-brand-lightgrey-light;

            &:not(.is-active) {
                display: none;
            }

            &:focus {
                outline: {
                    width: rem(2);
                    style: solid;
                    color: $color-brand-lightgrey;
                    offset: rem(-4);
                }
            }
        }

        &__title {
            display: none;
        }
    }
}
