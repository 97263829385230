.l-Site {
    $root: &;

    position: relative;

    &__skipLinks {

    }

    &__masthead {
        position: sticky;
        top: 0;
        z-index: z(site-masthead);
    }

    &__warnings {

    }

    &__body {
        background-color: $color-brand-white;

        &[tabindex="-1"]:focus {
            outline: none;
        }
    }

    &__page {

    }

    &__footer {

        //@include mq-from(site-bp2) {
        //    padding-bottom: rem(74);
        //}

        @include mq-from(dock-bp3) {
            padding-bottom: rem(80);
        }

        @include mq-from(masthead-bp2) {
            padding-bottom: 0;
        }
    }

    &__dock {
        position: fixed;
        z-index: z(site-dock);
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;

        //@include mq-from(site-bp2) {
        //    display: none;
        //}

        @include mq-from(masthead-bp2) {
            display: none;
        }
    }

    &__modal {

    }
}
