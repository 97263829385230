.ElementForm {
    $root: &;

    max-width: rem(600);
    width: 100%;

    &__form {

    }
}
