.ElementSubscribe {
    $root: &;

    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    &--main {
        grid-gap: vr(2.5);

        @include mq-from(mainblock-bp2) {
            grid-template-columns: 1.5fr 1fr;
            grid-gap: vr(4);
        }

        #{$root} {

        }
    }

    &--side {
        grid-gap: vr(1);

        #{$root} {

            &__action {
                margin-top: vr(1);
            }
        }
    }

    &__form {

    }

    &__field {
        margin-bottom: vr(0.5);
    }

    &__label {

    }
}

.o-SideBlock--ElementSubscribe {

    @include mq-before(site-bp3) {
        display: none;
    }
}

//.Sidebar__block--SectionNav {
//
//    @include mq-before(site-bp3) {
//        display: none;
//    }
//}
