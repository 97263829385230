.ListControls
{
    border: {
        top: rem(1) solid $color-brand-grey-light;
        bottom: rem(1) solid $color-brand-grey-light;
    }
    padding: {
        top: vr(1);
        bottom: vr(1);
    }
    min-height: vr(5);

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    &__summary {
        width: 100%;
        font-size: rem(14);
        line-height: rem(14);
        margin-bottom: vr(0.5);

        @include mq-from(listcontrols-bp0) {
            margin-bottom: 0;
            width: auto;
            padding-right: vr(1);
        }
    }

    &__sort {
        width: 100%;

        @include mq-from(listcontrols-bp0) {
            max-width: rem(170);
        }

        @include mq-from(listcontrols-bp1) {
            max-width: rem(250);
        }
    }

    &__control {
        display: block;
        margin-bottom: vr(1);

        @include mq-from(listcontrols-bp2) {

            @supports (display: flex) {
                margin-bottom: 0;
            }
        }

    //    &--date {
    //        width: 100%;
    //
    //        @include mq-from(listcontrols-bp1) {
    //
    //            @supports (display: flex) {
    //                flex: 1;
    //                padding-right: vr(0.5);
    //            }
    //        }
    //
    //        @include mq-from(listcontrols-bp2) {
    //
    //            @supports (display: flex) {
    //                width: rem(220);
    //                flex: initial;
    //            }
    //        }
    //    }
    //
    //    &--filter {
    //
    //        @supports (display: flex) {
    //            width: 45%;
    //            padding-right: vr(0.5);
    //        }
    //
    //        @include mq-from(listcontrols-bp1) {
    //
    //            @supports (display: flex) {
    //                width: auto;
    //                padding-left: vr(0.5);
    //            }
    //        }
    //    }
    //
    //    &--categories {
    //
    //        @supports (display: flex) {
    //            width: 55%;
    //            padding-left: vr(0.5);
    //        }
    //
    //        @include mq-from(listcontrols-bp1) {
    //
    //            @supports (display: flex) {
    //                width: auto;
    //                padding-left: vr(0.5);
    //            }
    //        }
    //
    //        @include mq-from(listcontrols-bp4) {
    //            display: none;
    //        }
    //    }

        &--sort {
            @include clearfix;
            width: 100%;
            margin-bottom: 0;

            @include mq-from(listcontrols-bp2) {

                @supports (display: flex) {
                    flex: 1;
                    padding-left: vr(1);
                }
            }

            @include mq-from(listcontrols-bp3) {

                @supports (display: flex) {
                    width: rem(250);
                    flex: initial;
                    margin-left: auto;
                }
            }
        }
    }

    &__label {
        float: left;
        margin-right: vr(1);
        line-height: vr(3);
        font-size: rem(14);
        width: vr(4.5);
    }

    &__select {
        float: left;

        &:not([multiple]) {

            &:not([disabled]),
            &:not([readonly]) {
                width: calc(100% - #{vr(5.5)});
                font-size: rem(14);
                line-height: vr(2);
                height: vr(3);
                background-size: vr(1.25);
                font-weight: $font-weight-normal;
                border-color: $color-brand-grey-light;
                padding: {
                    top: vr(0.5);
                    bottom: vr(0.5);
                }
            }

            &:focus {
                outline-color: $color-brand-grey-calm;
                outline-width: rem(2);
                background-color: $color-brand-blue-harmony;
            }
        }
    }

    //&__counter {
    //    margin-top: vr(1);
    //    padding-top: vr(1);
    //    border-top: rem(1) solid $color-brand-grey-pale;
    //}
}
