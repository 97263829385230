.Button {
    $root: &;

    &.-size {

        &-xs {
            font-size: rem(14);
            line-height: vr(1.5);
            border-width: rem(1);
            padding: {
                top: vr(0.5);
                bottom: vr(0.5);
            }
            min-height: vr(2.5);

            &:not(#{$root}--text) {
                padding: {
                    left: vr(1);
                    right: vr(1);
                }
            }

            #{$root} {

                &__label {
                    font-size: rem(14);
                    line-height: vr(1.5);
                }

                &__icon {
                    height: rem(14);
                }
            }
        }

        &-sm {
            font-size: rem(16);
            line-height: vr(1.5);
            padding: {
                top: vr(0.5);
                bottom: vr(0.5);
            }

            &:not(#{$root}--text) {
                padding: {
                    left: vr(1);
                    right: vr(1);
                }
            }

            #{$root} {

                &__label {
                    font-size: rem(14);
                    line-height: vr(1.5);
                }

                &__icon {
                    height: rem(16);
                }
            }
        }

        &-lg {
            font-size: rem(20);
            line-height: vr(2);
            border-width: rem(2);

            #{$root} {

                &__label {
                    font-size: rem(20);
                    line-height: vr(2);
                }

                &__icon {
                    height: rem(20);
                }
            }
        }
    }
}
