.FooterMenu {
    $root: &;

    &__title {
        font-size: rem(16);
        line-height: vr(1.5);
        padding-bottom: vr(1.5);
        border-bottom: rem(1) solid $color-brand-white;
        margin-bottom: vr(1.5);
        font-weight: $font-weight-medium;
    }

    &__items {

    }

    &__item {
        padding-bottom: vr(0.75);
        font-size: rem(14);
        line-height: vr(1.75);

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__link,
    &__text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
    }

    &__link {

        &:hover,
        &:active,
        &:focus {

            #{$root} {

                &__label {
                    text-decoration: underline;
                }
            }
        }
    }

    &__icon {
        flex: 0 0 auto;
        width: vr(1.75);

        ~ #{$root}__label {
            padding-left: vr(1);
        }
    }

    &__label {
        flex: 0 1 auto;
    }
}
