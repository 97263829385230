.Tile {
    $root: &;

    position: relative;
    width: 100%;

    background-color: $color-brand-white;
    border-left: rem(2) solid $color-brand-lightgrey-light;
    padding: vr(1.5);
    height: 100%;

    &:hover {
        background-color: $color-brand-lightgrey-pale;
    }

    &:focus-within {
        border-color: $color-brand-lightgrey;
        background-color: $color-brand-lightgrey-pale;
    }

    &__hd {
        margin-bottom: vr(1.5);
    }

    &__bd {
        margin: {
            top: vr(1.5);
            bottom: vr(1.5);
        }
    }

    &__ft {
        margin-top: vr(1.5);
        position: relative;
        z-index: 1;
    }

    &__hd,
    &__bd {

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__media {
        position: relative;
        overflow: hidden;
        height: 0;
        padding-bottom: 60%;

        &--square {
            padding-bottom: 100%;
        }
    }

    &__picture {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__img {
        object-fit: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__attributes {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        max-height: 100%;
        padding: {
            left: vr(1);
            right: vr(1);
            bottom: vr(1);
        }
        margin-bottom: vr(-0.5);
    }

    &__attribute {
        display: inline-block;
        position: relative;
        z-index: 1;
        margin: {
            right: vr(0.5);
            bottom: vr(0.5);
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__title {
        font-size: rem(24);
        line-height: vr(2.25);
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
    }

    &__link {
        display: block;
        position: static;

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &__subtitle {
        font-size: rem(20);
        font-weight: $font-weight-light;
        line-height: vr(2);
        margin-bottom: vr(1.5);
        color: $color-brand-darkgrey-light;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__summary {
        font-size: rem(16);
        line-height: vr(2);
        color: $color-brand-darkgrey-light;
    }

    &__metas {
        font-size: rem(16);
        line-height: vr(2);
        font-weight: $font-weight-light;
        color: $color-brand-darkgrey-light;
        margin-bottom: vr(1.5);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__meta {
        display: inline;

        &:after {
            content: ' | ';
        }

        &:last-child:after {
            content: none;
        }
    }

    &__metaLink {
        color: inherit;
        text-decoration: underline;
        position: relative;
        z-index: 1;

        &:hover,
        &:active,
        &:focus {
            color: $color-brand-nero;
        }

        &:focus {
            outline: none;
        }
    }

    &__action {
        color: $color-brand-palmgreen;
        font-weight: $font-weight-medium;

        &:not(:first-child) {
            margin-top: vr(1);
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }
    }

    &__type {
        background-color: rgba($color-brand-palmgreen, 0.2);
        color: $color-brand-palmgreen;
        display: inline-block;
        padding: vr(0.25) vr(0.75);
        margin-bottom: vr(1);
        border-radius: vr(0.5);
        font-size: rem(12);
        line-height: rem(15);
        font-weight: $font-weight-medium;
    }

    &__date {
        font-size: rem(14);
        line-height: rem(15);
        font-weight: $font-weight-medium;
        color: $color-brand-darkgrey-pale;

        &:not(:first-child) {
            margin-top: vr(0.5);
        }
    }

    &--resourceset,
    &--document,
    &--video {

        #{$root} {

            &__type {
                background-color: rgba($color-brand-heritagered, 0.2);
                color: $color-brand-heritagered;
            }

            &__action {
                color: $color-brand-heritagered;
            }
        }
    }

    &--event {

        #{$root} {

            &__type {
                background-color: rgba($color-brand-pacificblue, 0.2);
                color: $color-brand-pacificblue;
            }

            &__action {
                color: $color-brand-pacificblue;
            }
        }
    }
}
