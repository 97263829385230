.Toolbar {
    $root: &;


    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: $color-brand-darkgrey-light;
    font-size: rem(12);
    line-height: vr(1.5);
    padding: {
        top: vr(0.5);
        bottom: vr(0.5);
    }
    //padding-left: calc(#{vr(1)} + #{rem(1)});

    //@include mq-from(header-bp3) {
    //    padding-left: calc(#{vr(1.5)} + #{rem(1)});
    //}

    &__message {
        padding-right: vr(2);
        //display: none;

        //@include mq-from(header-bpD) {
        //    display: block;
        //}

        //@include mq-from(header-bp1) {
        //    display: block;
        //}

        a {
            text-decoration: underline;

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }

    &__tools {
        white-space: nowrap;
        margin-left: auto;
    }

    &__tool {
        display: inline-block;
        margin-right: vr(0.75);
        padding-left: vr(1);
        border-left: rem(1) solid rgba($color-brand-grey-calm, 0.25);

        &:first-child {
            border-left: 0;
            padding-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &--highlight {
            font-weight: $font-weight-bold;
            //text-transform: uppercase;
        }

        &--translator {
            margin: {
                top: vr(-0.75);
                bottom: vr(-0.75);
            }
            margin-right: 0;
        }
    }

    &__toolLink {

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }
    }


}
