$fonts-path-satoshi: $fonts-path + 'satoshi-variable/';

@font-face {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 300 900;
    font-display: swap;
    src: url($fonts-path-satoshi + 'Satoshi-Variable.woff2') format('woff2'),
    url($fonts-path-satoshi + 'Satoshi-Variable.woff') format('woff');
    unicode-range: $unicode-range-latin;
}

@font-face {
    font-family: 'Satoshi';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url($fonts-path-satoshi + 'Satoshi-VariableItalic.woff2') format('woff2'),
    url($fonts-path-satoshi + 'Satoshi-VariableItalic.woff') format('woff');
    unicode-range: $unicode-range-latin;
}
