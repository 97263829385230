.ElementFeature {
    $root: &;
    display: grid;
    grid-gap: vr(5.5);
    grid-template-columns: 1fr;
    align-items: center;

    @include mq-from(mainblock-bp2) {
        grid-template-columns: 1fr 1fr;
    }

    &.-mediapos {

        &-east {

            #{$root} {

                &__content {
                    order: 1;
                }

                &__media {
                    order: 2;
                }
            }
        }

        &-west {

            #{$root} {

                &__content {
                    order: 2;
                }

                &__media {
                    order: 1;
                }
            }
        }
    }

    &__media {

    }

    &__picture {
        display: block;
        width: 100%;

        @include mq-from(mainblock-bp1) {

            &:not(.-ratio-natural) {
                height: 100%;
                min-height: vr(20);
            }
        }
    }

    &__img {
        display: block;
        width: 100%;

        @include mq-from(mainblock-bp1) {

            &:not(.-ratio-natural) {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__content {

    }

    &__lede {
        margin-bottom: vr(2.5);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__cta {
        margin: {
            top: 0;
            bottom: 0;
        }
    }
}
