.ElementStatistics {

}

.StatsTile {
    padding: vr(3);
    border: rem(1) solid #babab9;
    background-color: #FDFBFA;
    height: 100%;

    @include mq-from(stats-bp1) {
        display: grid;
        grid-template-columns: rem(100) 1fr;
        align-items: center;
    }

    @include mq-from(stats-bp4) {
        grid-template-columns: rem(150) 1fr;
    }

    &__icon {
        order: 1;
        padding-right: vr(3);

        @include mq-before(stats-bp1) {
            display: none;
        }

        @include mq-from(stats-bp4) {
            padding: {
                top: vr(1.5);
                bottom: vr(1.5);
                //left: vr(1.5);
            }
        }

        @include mq-from(stats-bp5) {
            padding-left: vr(1.5);
            padding-right: vr(4.5);
        }
    }

    &__img {

    }

    &__content {
        order: 2;
    }

    &__value {
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        //font-size: rem(60);
        font-size: rem(40);
        line-height: 1;
        margin-bottom: vr(0.5);

        @include mq-from(stats-bp2) {
            font-size: rem(50);
        }
    }

    &__small {
        display: block;
        margin-top: vr(0.5);
        //font-size: rem(36);
        font-size: rem(32);
        line-height: 1;

        @include mq-from(stats-bp2) {
            font-size: rem(32);
        }
    }

    &__summary {
        //font-size: rem(20);
        //line-height: vr(2.25);
        font-size: rem(18);
        line-height: vr(2);

        @include mq-from(stats-bp3) {
            font-size: rem(20);
            line-height: vr(2.25);
        }
    }
}
