.Button {
    $root: &;

    font-weight: $font-weight-bold;
    cursor: pointer;
    border: {
        width: rem(2);
        style: solid;
        color: transparent;
        radius: vr(0.5);
    }
    padding: {
        top: vr(1);
        bottom: vr(1);
        left: vr(1.25);
        right: vr(1.25);
    }
    transition: background-color 0.2s ease,
                opacity 0.2s ease,
                border-color 0.2s ease,
                color 0.2s ease;

    outline: none;

    font-size: rem(16);
    line-height: vr(1.75);

    position: relative;
    top: 0;
    width: auto;
    vertical-align: middle;
    display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    outline: {
        width: rem(2);
        style: solid;
        color: transparent;
        offset: rem(-6);
    }

    background-color: transparent;
    border-color: $color-brand-black;
    color: transparent;

    ::selection {
        background: transparent;
        color: currentColor;
    }

    min-height: vr(3);

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: vr(3);
    }

    &:not(.is-loading) {
        color: $color-brand-black;
    }

    &:not([disabled]):not(.is-loading):not(#{$root}--text) {

        &:hover,
        &:focus,
        &:active {
            background-color: $color-brand-black;
            color: $color-brand-white;
        }

        &:active {
            top: rem(2);
        }
    }

    //&:focus {
    //    outline: none;
    //}

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.75;
    }

    &__label {
        display: inline-block;
        vertical-align: middle;
        font-size: rem(18);

        /*
           Font-family-specific clipping of line-height to correctly visually center text
           ---
           ref: https://medium.com/codyhouse/line-height-crop-a-simple-css-formula-to-remove-top-space-from-your-text-9c3de06d7c6f
           ref: http://text-crop.eightshapes.com/?typeface-selection=google-font&typeface=Montserrat&custom-typeface-name=Helvetica&custom-typeface-url=&custom-typeface-weight=400&custom-typeface-style=normal&weight-and-style=500&size=36&line-height=1.2&top-crop=9&bottom-crop=8
           ref: https://iamvdo.me/en/blog/css-font-metrics-line-height-and-vertical-align
         */

        ~ #{$root}__icon {
            margin-left: vr(0.75);
        }
    }

    &__icon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        height: rem(20);
        width: auto;
        min-width: rem(1);

        &--lg {
            height: rem(24);
            margin: {
                top: rem(-2);
                bottom: rem(-2);
            }
        }

        &--xl {
            height: rem(30);
        }

        ~ #{$root}__label {
            margin-left: vr(0.75);
        }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

        @at-root {

            .no-js #{$root}__icon {
                display: none;

                + #{$root}__label {
                    margin-left: 0;
                }
            }
        }
    }

    &--text {
        border: none;
        min-height: auto;
        text-transform: none;
        font-weight: $font-weight-normal;
        padding: {
            left: 0;
            right: 0;
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }
    }

    &--fill,
    &--fileField {
        display: block;
        display: flex;
        width: 100%;
        min-width: 0;
    }

    &--fileField {

        #{$root} {

            &__label {

                &--filename {
                    display: none;
                }
            }
        }

        &.-has-file {
            justify-content: start;
            text-transform: inherit;
            font-weight: inherit;
            font-family: inherit;
            text-align: left;

            #{$root} {

                &__label {

                    &--empty {
                        display: none;
                    }

                    &--filename {
                        display: inline-block;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                &__icon {
                    display: none;
                }
            }
        }
    }

    &.-toggle {

        #{$root}__icon {

            &--close {
                display: none;
            }
        }

        &.is-open {

            #{$root}__icon {

                &--open {
                    display: none;
                }

                &--close {
                    display: inline-block;
                }
            }
        }
    }

    &.is-loading {
        background-color: $color-brand-black;
        cursor: not-allowed;
        opacity: 0.75;

        &:after {
            border-top-color: $color-brand-white;
        }

        @keyframes spinner {
            to {transform: rotate(360deg);}
        }

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: {
                top: vr(-1.25);
                left: vr(-1.25);
            }
            width: vr(2.5);
            height: vr(2.5);
            content: '';
            box-sizing: border-box;
            border-radius: 50%;
            border-top: rem(4) solid transparent;
            border-right: rem(4) solid transparent;
            animation: spinner 0.8s linear infinite;
        }

        #{$root} {

            &__icon,
            &__label {
                visibility: hidden;
            }
        }
    }

    &.-wrap-no {

        #{$root}__label {
            white-space: nowrap;
        }
    }

    &.-align {

        &-left {
            text-align: left;
            justify-content: flex-start;
        }

        &-right {
            text-align: right;
            justify-content: flex-end;
        }

        &-center {
            text-align: center;
            justify-content: center;
        }
    }

    // default: inherit
    text-transform: inherit;
    &.-case {

        &-upper {
            text-transform: uppercase;
        }

        &-lower {
            text-transform: lowercase;
        }

        &-inherit {
            text-transform: inherit;
        }

        &-none {
            text-transform: none;
        }

        &-cap {
            text-transform: capitalize;
        }
    }
}
