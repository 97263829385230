.SectionNav {
    $root: &;

    &__menu {
        background-color: $color-brand-white;
        color: $color-brand-darkgrey;
        font-weight: $font-weight-medium;
        font-size: rem(16);
        line-height: vr(1.5);
    }

    &__item {
        display: flex;
        flex-wrap: wrap;
        border-bottom: rem(1) solid $color-brand-lightgrey-light;
    }

    &__link {
        flex: 1 1 auto;
        border-left: vr(0.25) solid transparent;
        padding: {
            top: vr(1.25);
            bottom: vr(1.25);
            left: vr(1.25);
            right: vr(2);
        }

        .is-open & {
            background-color: $color-brand-lightgrey-pale;
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
            background-color: $color-brand-lightgrey-pale;
        }

        &--current,
        &--section {
            border-left-color: $color-brand-warmorange;
            background-color: $color-brand-lightgrey-pale;
            font-weight: $font-weight-semi-bold;
        }
    }

    &__toggle {
        cursor: pointer;
        border: none;
        padding: vr(1);
        min-width: vr(3.5);
        min-height: vr(3.5);
        text-align: center;
        line-height: vr(1.5);
        color: currentColor;
        background-color: transparent;
        text-decoration: none;

        #{$root}__link--current + &,
        #{$root}__link--section + &,
        .is-open &,
        &:hover,
        &:active {
            background-color: $color-brand-lightgrey-pale;
        }
    }

    &__icon {
        height: vr(2);

        &--down {
            display: block;

            .is-open & {
                display: none;
            }
        }

        &--up {
            display: none;

            .is-open & {
                display: block;
            }
        }
    }

    &__submenu {
        display: none;
        background-color: $color-brand-lightgrey-pale;
        font-weight: $font-weight-medium;
        font-size: rem(14);
        line-height: vr(1.25);
        width: 100%;

        .is-open & {
            display: block;
        }
    }

    &__subitem {

        &:first-child {
            border-top: rem(1) solid $color-brand-lightgrey-light;
        }

        &:not(:last-child) {
            border-bottom: rem(1) solid $color-brand-lightgrey-light;
        }
    }

    &__sublink {
        display: block;
        border-left: vr(0.25) solid transparent;
        padding: {
            top: vr(1.25);
            bottom: vr(1.25);
            left: vr(1.75);
            right: vr(2);
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }

        &--current,
        &--section {
            border-left-color: $color-brand-warmorange;
            font-weight: $font-weight-semi-bold;
        }
    }
}
