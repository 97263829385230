$modal-overlay-bgcolor: rgba($color-brand-black, 0.9);
$modal-main-bgcolor: $color-brand-silver;
$modal-hd-bgcolor: $color-brand-green-dark;
$modal-hd-bordercolor: transparent;
$modal-ft-bgcolor: $color-brand-ghost;
$modal-title-color: $color-brand-silver;
$modal-title-fontsize: rem(18);
$modal-title-lineheight: vr(1.75);
$modal-title-fontweight: $font-weight-semi-bold;
$modal-title-fontfamily: inherit;
$modal-title-texttransform: none;

.Modal {
    $root: &;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-brand-pacificblue;
    color: $color-brand-white;
    z-index: z(site-modal);

    &[aria-hidden="true"] {
        display: none;
    }

    &__overlay {

    }

    &__main {
        height: 100%;
        width: 100%;
        //background-color: $modal-main-bgcolor;
        display: flex;
        flex-direction: column;
        position: relative;
        margin: {
            left: auto;
            right: auto;
        }

        &:before {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            background: transparent  #{assetspath('images/overlay_03.png')} scroll no-repeat top right;
            background-size: contain;
            opacity: 0.25;
        }

        //@include mq-from(modal-bp1) {
        //    height: auto;
        //    width: rem(1024);
        //    max-width: calc(100% - #{vr(4)});
        //    position: absolute;
        //    top: 50%;
        //    left: 50%;
        //    transform: translate(-50%,-50%);
        //
        //    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        //        height: 100%;
        //        width: 100%;
        //    }
        //}
    }

    &__hd {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: vr(1);
        background-color: $color-brand-pacificblue-light;
        //border-bottom: rem(1) solid $modal-hd-bordercolor;
    }

    &__bd {
        position: relative;
    }

    &__ft {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: vr(1) vr(2);
        background-color: $color-brand-pacificblue-light;
    }

    &__title {
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        font-size: rem(18);
        line-height: vr(1.75);
        padding-left: vr(0.5);
        //padding: {
        //    left: vr(1);
        //    right: vr(1);
        //};
    }

    &__close {

    }

    &__bd {
        flex: 1;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    &__content {
        padding: vr(1);
        max-width: rem(1024);
        margin: {
            left: auto;
            right: auto;
        }

        @include mq-from(modal-bp1) {
            padding: vr(2);
        }
    }

    &--menu {

        #{$root} {

            &__content {
                padding: 0;
            }
        }
    }

    //&--register {
    //
    //    #{$root} {
    //
    //        &__main {
    //
    //            @include mq-from(modal-bp1) {
    //                width: rem(460);
    //            }
    //        }
    //    }
    //}
}
