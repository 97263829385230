.o-List {
    $root: &;

    &__item {

        &:not(:last-child) {
            margin-bottom: vr(1.5);
        }
    }

    &--sm {

        #{$root} {

            &__item {

                &:not(:last-child) {
                    margin-bottom: vr(0.75);
                }
            }
        }
    }
}
