.NextPrevGroup {
    @include clearfix;

    border-top: rem(1) solid $color-brand-lightgrey-light;
    padding-top: vr(3);

    &__item {
        width: vr(12);

        &--prev {
            float: left;
        }

        &--next {
            float: right;
            text-align: right;
        }
    }
}

.l-Site__block--related {
    $root: &;

    border-top: rem(1) solid $color-brand-lightgrey-light;

    .o-Block {
        background-color: $color-brand-lightgrey-pale;
    }
}
