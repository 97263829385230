.MainMenu {
    $root: &;

    &__items {
        display: flex;
        font-size: rem(15);

        @include mq-from(mainmenu-bp1) {
            font-size: rem(16);
        }

        @include mq-from(mainmenu-bp2) {
            font-size: rem(18);
        }
    }

    &__item {
        position: relative;
        font-weight: $font-weight-medium;
        color: $color-brand-darkgrey;

        &.-has-mega {
            position: static;
        }

        &:hover,
        &:active,
        &:focus {

            #{$root} {

                &__link {
                    color: $color-brand-warmorange-dark;
                }

                &__label {
                    border-bottom-color: currentColor;
                }

                &__mega,
                &__submenu {
                    display: block;
                }
            }
        }

        &:focus-within {

            #{$root} {

                &__link {

                }

                &__mega,
                &__submenu {
                    display: block;
                }
            }
        }

        &--current,
        &--section {

            #{$root} {

                &__link {
                    font-weight: $font-weight-bold;
                    color: $color-brand-warmorange-dark;
                }
            }
        }
    }

    &__link {
        //z-index: z(masthead-mainmenu);
        display: flex;
        align-items: center;
        position: relative;
        padding: {
            left: vr(1);
            right: vr(1);
            top: vr(0.5);
            bottom: vr(0.5);
        };

        @include mq-from(mainmenu-bp1) {
            padding: {
                left: vr(1.25);
                right: vr(1.25);
            };
        }

        @include mq-from(mainmenu-bp2) {
            padding: {
                left: vr(1.5);
                right: vr(1.5);
            };
        }

        &:focus {
            outline: {
                width: rem(1);
                style: auto;
                color: currentColor;
                offset: 0;
            }
        }
    }

    &__label {
        padding: {
            top: vr(0.25);
            bottom: vr(0.25);
        };
        border: {
            top: vr(0.25) solid transparent;
            bottom: vr(0.25) solid transparent;
        }
    }

    &__down {
        flex: 0 0 auto;
        width: vr(0.75);
        margin-left: vr(0.75);
    }

    &__submenu {
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        min-width: rem(190);

        @include mq-from(mainmenu-bp1) {
            min-width: rem(200);
        }

        @include mq-from(mainmenu-bp2) {
            min-width: rem(235);
        }

        @at-root .headroom--not-top & {
            padding-top: rem(11);
        }
    }

    &__close {
        display: none;
    }
}
