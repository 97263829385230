.o-Block {
    $root: &;

    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: z(block-background-overlay);
        background-color: currentColor;
    }

    &__container {
        position: relative;
        padding: {
            top: vr(5.5);
            bottom: vr(5.5);
        }
    }

    &__main {
        position: relative;
        z-index: z(block-body);
    }

    &__hd,
    &__bd {
        position: relative;
    }

    &__hd {
        max-width: $content-max-width;

        + #{$root}__bd {
            margin-top: vr(5);
        }
    }

    &__bd {

    }

    &__title {
        font-size: rem(36);
        line-height: vr(3.5);
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        margin-bottom: vr(2.5);

        &:last-child {
            margin-bottom: 0;
        }

        + #{$root}__subtitle {
            margin-top: vr(-0.75);
        }
    }

    &__subtitle {
        font-size: rem(24);
        font-weight: $font-weight-medium;
        line-height: vr(2.5);
        margin-bottom: vr(2.5);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__cta {
        margin: {
            top: vr(-1.5);
            bottom: vr(-1.5);
        }
    }

    &__button {

    }

    &__groups {
        display: grid;
        grid-gap: vr(5.5);
        grid-template-columns: 1fr;
        align-items: start;

        @include mq-from(mainblock-bp2) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__group {
        display: grid;
        grid-gap: vr(2);
        grid-template-columns: 1fr;
        justify-items: start;
    }

    &__groupTitle {
        font-size: rem(36);
        line-height: vr(4);
        font-weight: $font-weight-medium;
        margin-bottom: vr(0.5);
    }

    &__element {
        width: 100%;
    }

    // MODIFIERS

    &.-style {

        &-box {

            &.-t-bgcolor {

                @include mq-from(mainblock-bp2) {
                    background-color: transparent;
                }
            }

            #{$root} {

                &__container {
                    margin: {
                        top: vr(5.5);
                        bottom: vr(5.5);
                    }

                    @include mq-from(mainblock-bp2) {
                        margin: {
                            top: 0;
                            bottom: 0;
                        }
                    }
                }

                &__main {

                    @include mq-from(mainblock-bp2) {
                        padding: vr(5.5) vr(4.5);
                    }

                    &.-t-bgcolor {

                        @include mq-before(mainblock-bp2) {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }

    &:not(.-style-box) {

        #{$root} {

            &__main {

                &.-t-bgcolor {
                    background-color: transparent;
                }
            }
        }
    }

    &.-layout {

        &-indented {

            #{$root} {

                @include mq-from(mainblock-bp2) {

                    &__main {
                        display: grid;
                        grid-template-columns: 1fr 2fr;
                        grid-column-gap: vr(2.5);
                    }

                    &__hd {
                        grid-row: 1;
                        grid-column: 2;

                        + #{$root}__bd {
                            grid-row: 2;
                            grid-column: 2;
                        }
                    }

                    &__bd {
                        grid-row: 1;
                        grid-column: 2;
                    }
                }
            }
        }

        &-horizontal {

            #{$root} {

                @include mq-from(mainblock-bp2) {

                    &__main {
                        display: grid;
                        grid-template-columns: 1fr 2fr;
                        grid-column-gap: vr(2.5);
                    }

                    &__hd {

                        + #{$root}__bd {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    &.-pad {

        &-top-off {

            &:not(.-style-box) {

                #{$root}__container {
                    padding-top: 0;
                }
            }

            &.-style-box {

                #{$root}__container {
                    margin-top: 0;

                    @include mq-from(mainblock-bp2) {
                        padding-top: 0;
                    }
                }
            }
        }

        &-bottom-off {

            &:not(.-style-box) {

                #{$root}__container {
                    padding-bottom: 0;
                }
            }

            &.-style-box {

                #{$root}__container {
                    margin-bottom: 0;

                    @include mq-from(mainblock-bp2) {
                        padding-bottom: 0;
                    }
                }
            }
        }

        &-left-off {
            padding-left: 0;

            #{$root}__container {
                margin-left: 0;
            }

            &.-pad-right-off {

                #{$root}__container {
                    max-width: none;
                }
            }
        }

        &-right-off {
            padding-right: 0;

            #{$root}__container {
                margin-right: 0;
            }
        }
    }

    &.-halign {

        &-left {

            #{$root} {

                &__hd,
                &__ft {
                    text-align: left;
                }
            }
        }

        &-right {

            #{$root} {

                &__hd,
                &__ft {
                    text-align: right;
                }
            }
        }
    }

    &.-border {

        &-top-on {

            #{$root}__container {
                border-top: rem(1) solid rgba($color-brand-grey-calm, 0.25);
            }
        }

        &-bottom-on {

            #{$root}__container {
                border-bottom: rem(1) solid rgba($color-brand-grey-calm, 0.25);
            }
        }
    }

    &.-theme {

        &-white {

        }

        &-grey {
            background-color: $color-brand-lightgrey-pale;
        }

        &-lightshell {
            background-color: $color-brand-conchshell-pale;
        }

        &-shell {
            background-color: $color-brand-conchshell;
        }
    }
}

.-theme {

    &-default {

        &.-t-bgcolor,
        .-t-bgcolor {
            //background-color: pink;
        }
    }

    &-black {

        &.-t-bgcolor,
        .-t-bgcolor {
            background-color: $color-brand-nero;
        }

        &.-t-textcolor,
        .-t-textcolor {
            color: $color-brand-ghost;
        }
    }

    &-yellow {

        &.-t-bgcolor,
        .-t-bgcolor {
            background-color: $color-brand-silver;
        }

        &.-t-textcolor,
        .-t-textcolor {
            color: $color-brand-nero;
        }
    }
}
