.ElementLinks {
    $root: &;

    &__groups {
        grid-row-gap: 0;
        grid-column-gap: nth($site-gutters, 2);

        @include mq-from(container-bp2) {
            grid-column-gap: nth($site-gutters, 3);
        }
    }

    &__group {

    }

    &__items {

    }

    &__item {
        border-top: rem(1) solid $color-brand-grey;
    }
}
