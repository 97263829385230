.Breadcrumbs {
    $root: &;

    &__crumbs {
        margin-left: vr(-0.5);
    }

    &__crumb {
        display: inline-block;
        font-size: rem(14);
        line-height: vr(1.5);

        &:after {
            content: '/';
            display: inline-block;
            padding: {
                left: vr(0.25);
                right: vr(0.25);
            }
        }

        &:last-child {

            &:after {
                content: none;
            }

            #{$root}__link {
                opacity: 0.55;

                &:hover,
                &:active,
                &:focus {
                    opacity: 1;
                }
            }
        }
    }

    &__link {
        display: inline-block;
        color: inherit;
        text-decoration: none;
        opacity: 0.85;

        &:hover,
        &:active,
        &:focus {
            opacity: 1;

            #{$root}__label {
                text-decoration: underline;
            }
        }

        &:focus {
            outline: {
                width: rem(1);
                style: solid;
                color: currentColor;
            }
        }
    }

    &__label {
        display: inline-block;
        padding: {
            top: vr(0.25);
            bottom: vr(0.25);
            left: vr(0.5);
            right: vr(0.5);
        }
    }
}
