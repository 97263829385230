.Translator {
    margin-bottom: rem(-2);

    .goog-te-gadget-simple {
        border: none;
        background: transparent;

        .goog-te-gadget-icon {
            display: none;
        }

        .goog-te-menu-value {
            font-weight: $font-weight-medium;
            font-family: $font-family-body;
            cursor: pointer;
            color: $color-brand-darkgrey-light;
            line-height: vr(1.25);
            font-size: rem(12);
            padding: {
                top: vr(0.75);
                bottom: vr(0.75);
                left: vr(1);
                right: vr(1);
            }
            transition: all 0.2s ease;
            outline: {
                width: rem(2);
                style: solid;
                color: transparent;
                offset: rem(-6);
            }
            margin: {
                left: 0;
                right: 0;
                top: rem(-1);
            }
            display: block;

            //&:focus {
            //    outline-color: rgba($color-brand-blue-true, 0.8);
            //}
            //&:focus:not(:focus-visible) {
            //    outline-color: transparent;
            //}
            //&:focus-visible {
            //    outline-color: rgba($color-brand-blue-true, 0.8);
            //}

            &:hover,
            &:active,
            &:focus {

                > span:first-child {
                    text-decoration: underline;
                }
            }

            > span[style] {
                border-left: 0 !important;
                margin-right: rem(-3);
            }

            > span[aria-hidden] {
                color: $color-brand-darkgrey-light !important;
                margin-right: 0;
                font-size: 90%;
            }
        }
    }
}

.goog-te-menu-frame {
    //left: 50% !important;
    //transform: translateX(-50%) !important;
}

html[class*='translated'] body {
    min-height: 0 !important;
    top: 0 !important;
    padding-top: 40px !important;
}
