$color-error: #c01a1a;
$color-success: #1e7522;
$color-warning: #db7500;
$color-info: #2e568a;

$color-white         : #FFFFFF;
$color-ghost         : #FAFAFA;
$color-snow          : #F9F9F9;
$color-vapor         : #F6F6F6;
$color-white-smoke   : #F5F5F5;
$color-silver        : #EFEFEF;
$color-smoke         : #EEEEEE;
$color-gainsboro     : #DDDDDD;
$color-grey          : #CCCCCC;
$color-silver        : #BBBBBB;
$color-base          : #AAAAAA;
$color-aluminum      : #999999;
$color-jumbo         : #888888;
$color-monsoon       : #777777;
$color-steel         : #666666;
$color-charcoal      : #555555;
$color-tuatara       : #444444;
$color-oil           : #333333;
$color-jet           : #222222;
$color-nero          : #111111;
$color-black         : #000000;

$color-inherit		 : inherit;

$color-bandcamp		 : #4e9bac;
$color-delicious	 : #248cff;
$color-msedge        : #0067b8;
$color-facebook	 	 : #3b5998;
$color-ficly		 : #b51c2c;
$color-flickr		 : #0063db;
$color-github		 : #4183c4;
$color-googleplus	 : #dd4b39;
$color-instagram	 : #517fa4;
$color-javascript    : #ea1f72;
$color-kickstarter	 : #76cc1e;
$color-lanyrd		 : #152e51;
$color-lastfm		 : #d51007;
$color-linkedin		 : #007bb6;
$color-photodrop	 : #cdb6e3;
$color-pinterest	 : #cb2027;
$color-rdio			 : #0088da;
$color-soundcloud	 : #f60;
$color-twitter		 : #00aced;
$color-vimeo		 : #aad450;
$color-youtube		 : #b00;

$color-link: #0000FF;
$color-link-active: #EE0000;
$color-link-visited: #551A8B;

$color-mark: #000000;
$color-mark-background: yellow;
