@use "sass:math";
$form-margin-unit: vr(2);

.s-Form {

    label {
        color: $color-brand-grey-dark;
        font-weight: $font-weight-medium;
    }

    input[type='checkbox']:not(:only-child) + label,
    input[type='radio']:not(:only-child) + label {
        font-weight: $font-weight-normal;

        a {
            text-decoration: underline;
        }
    }

    > .message {
        margin-top: $form-margin-unit;
        margin-bottom: $form-margin-unit * 1.5;

        &:last-child {
            margin-bottom: 0;
        }
    }

    > fieldset {

        > legend {
            margin: {
                top: $cmscontent-margin-unit * 1.5;
                bottom: $form-margin-unit;
            }
            font-size: rem(24);
            font-weight: $font-weight-bold;
            line-height: vr(2.25);
            text-transform: uppercase;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        > {
            h1, h2, h3,
            h4, h5, h6 {
                color: currentColor;
                font-weight: $font-weight-light;
                margin-bottom: $cmscontent-margin-unit;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            h1 {
                font-size: rem(28);
                line-height: vr(2.75);
                margin-top: $cmscontent-margin-unit * 2.25;
            }

            h2 {
                color: $color-brand-green;
                font-size: rem(24);
                line-height: vr(2.25);
                margin-top: $cmscontent-margin-unit * 2.25;
            }

            h3 {
                font-size: rem(24);
                line-height: vr(2.25);
                margin-top: $cmscontent-margin-unit * 2;
            }

            h4 {
                color: $color-brand-green;
                font-size: rem(21);
                line-height: vr(2);
                margin-top: $cmscontent-margin-unit * 1.75;
            }

            h5 {
                font-size: rem(21);
                line-height: vr(2);
                font-weight: $font-weight-medium;
                margin-top: $cmscontent-margin-unit * 1.5;
            }

            h6 {
                font-size: rem(18);
                line-height: vr(1.75);
                font-weight: $font-weight-medium;
                margin-top: $cmscontent-margin-unit * 1.25;
            }
        }

        > label {
            display: block;
            font-size: rem(14);
            line-height: vr(1.25);
            margin-bottom: math.div($form-margin-unit, 4);

            &:last-child {
                margin-bottom: 0;
            }
        }

        + .btn-toolbar {
            //margin-top: $form-margin-unit;
        }
    }

    .field {
        margin-bottom: $form-margin-unit;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }

        .message {
            margin-top: math.div($form-margin-unit, 4);
        }

        > span {

            &.description {
                display: block;
                margin-top: math.div($form-margin-unit, 4);
                font-size: rem(12);
                line-height: vr(1);
                font-weight: $font-weight-normal;
                color: $color-brand-grey-dark;
                font-style: italic;
            }
        }

        > label {

            &.left {
                display: block;
                font-size: rem(14);
                line-height: vr(1.25);
                margin-bottom: math.div($form-margin-unit, 4);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        > .middleColumn {

            + label.right {

                @at-root {

                    .no-js & {
                        font-size: rem(14);
                        line-height: vr(1.25);
                        margin-top: math.div($form-margin-unit, 4);
                        display: block;
                        position: relative;
                        padding-left: vr(2);

                        &:before {
                            content: '';
                            background: transparent #{assetspath('svgs/alerts/info.svg')} scroll no-repeat center center;
                            background-size: 80%;
                            width: vr(1.5);
                            height: vr(1.5);
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }

                    .js & {
                        display: none;
                        // TODO: use JS to relocate ? icon and rightTitle (help) text into tooltip
                    }
                }
            }
        }

        &.is-required:not(.password) {

            > label.left:after {
                content: "*";
                padding-left: rem(3);
                color: $color-brand-heritagered;
            }
        }

        //&:not(.is-required):not(.password) {
        //
        //    > label.left:after {
        //        content: " (optional)";
        //        padding-left: rem(3);
        //    }
        //    [lang^=en] & > label.left:after {
        //        content: " (optional)";
        //        padding-left: rem(3);
        //    }
        //    [lang^=de] & > label.left:after {
        //        content: " (optional)";
        //        padding-left: rem(3);
        //    }
        //    [lang^=fr] & > label.left:after {
        //        content: " (facultatif)";
        //        padding-left: rem(3);
        //    }
        //    [lang^=it] & > label.left:after {
        //        content: " (facoltativo)";
        //        padding-left: rem(3);
        //    }
        //}


        &.optionset,
        &.checkboxset {

            .optionset,
            .checkboxset {
                > li {
                    margin-bottom: math.div($form-margin-unit, 4);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &.money {

            .form__fieldgroup {

                .fieldholder-small {
                    display: inline-block;
                    margin-right: math.div($form-margin-unit, 2);

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .fieldholder-small-label {
                    display: block;
                    font-size: rem(14);
                    line-height: vr(1.25);
                    margin-bottom: math.div($form-margin-unit, 4);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &.file {

            @at-root .no-js & {

                .Button {
                    display: none;
                }
            }

            @at-root .js & {

                label.left {
                    display: none;
                }

                .middleColumn {
                    border: 0;
                    clip: rect(0, 0, 0, 0);
                    height: rem(1);
                    overflow: hidden;
                    padding: 0;
                    position: absolute !important;
                    white-space: nowrap;
                    width: rem(1);
                }

                .middleColumn [type="file"]:focus,
                .middleColumn:focus-within {

                    + .Button {
                        outline: #{rem(1)} dotted currentColor;
                        outline: -webkit-focus-ring-color auto rem(5);
                    }
                }
            }
        }

        &.readonly {

            span.readonly {
                border: {
                    width: rem(1);
                    style: solid;
                }
                border-bottom-width: rem(1);
                border-radius: 0;
                display: block;
                line-height: vr(3);
                height: vr(4);
                width: 100%;
                padding: {
                    top: vr(0.5);
                    bottom: vr(0.5);
                    left: vr(1);
                    right: vr(1);
                }
                background-image: none;
                box-shadow: none;
                cursor: not-allowed;
            }
        }

        &.CompositeField {

            > label.left {
                margin-bottom: math.div($form-margin-unit, 2);

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.multicolumn {

                @include mq-from(forms-bp1) {
                    @include clearfix;

                    > .field {
                        float: left;
                        width: 50%;
                        margin-bottom: 0;

                        &:nth-child(odd) {
                            padding-right: vr(1);
                        }

                        &:nth-child(even) {
                            padding-left: vr(1);
                        }
                    }
                }

                > div[class*='column-'] {
                    margin-bottom: $form-margin-unit;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    //> .field {
                    //    margin-bottom: $form-margin-unit * 0.75;
                    //}
                    //
                    //&:last-child {
                    //
                    //    > .field {
                    //        margin-bottom: 0;
                    //    }
                    //}
                }
            }

            &.fieldgroup {

                .fieldgroup-field {
                    display: inline-block;
                    margin-right: math.div($form-margin-unit, 2);

                    &:last-child {
                        margin-right: 0;
                    }

                    .fieldholder-small-label {
                        display: block;
                        font-size: rem(14);
                        line-height: vr(1.25);
                        margin-bottom: math.div($form-margin-unit, 4);

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    fieldset.field.CompositeField {
        border: {
            width: rem(1);
            style: solid;
        }
        padding: $form-margin-unit * 0.75;

        > legend {
            font-size: rem(22);
            font-weight: $font-weight-normal;
            line-height: vr(2.25);
            padding: {
                left: vr(1);
                right: vr(1);
            }

            @include mq-from(cmscontent-bp1) {
                font-size: rem(30);
                line-height: vr(3);
            }
        }
    }

    .btn-toolbar {
        @include clearfix;
        margin-bottom: $form-margin-unit;

        &:last-child {
            margin-bottom: 0;
        }

        .action {
            float: left;
            margin-right: math.div($form-margin-unit, 2);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    //input:invalid,
    //textarea:invalid {
    //    border-radius: 1px;
    //    box-shadow: 0px 0px 5px red;
    //}
}
