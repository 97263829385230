.Person {
    $root: &;

    text-align: center;

    &__title {
        font-size: 24px;
        margin-bottom: 3px;
        margin-top: 12px;
        padding: 0 12px;
    }

    &__role {
        padding: 0 12px;
        font-size: 18px;
    }
}
