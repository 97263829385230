.BlogBanner {
    $root: &;

    position: relative;
    overflow: hidden;

    &__container {
        position: relative;
        z-index: z(hero-body);
        display: flex;
        align-items: center;
        padding: {
            top: vr(2);
            bottom: vr(2);
        }

        &.-theme {

            &-default {
                color: $color-brand-blue-expert;
                color: $color-brand-white;
            }

            &-blueharmony {
                color: $color-brand-blue-expert;
            }

            &-blueempower {
                color: $color-brand-white;
            }

            &-bluetrue {
                color: $color-brand-white;
            }

            &-blueexpert {
                color: $color-brand-white;
            }

            &-yellowaction {
                color: $color-brand-blue-expert;
            }

            &-greylight {
                color: $color-brand-blue-expert;
            }

            &-greycalm {
                color: $color-brand-white;
            }
        }
    }

    &__title {
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        font-size: rem(36);
        line-height: vr(3.5);
        margin-right: auto;
    }

    &__titleLink {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }
    }

    &__items {
        margin-left: auto;
    }

    &__item {
        display: inline-block;
        margin-right: vr(2);

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        color: inherit;
        text-decoration: none;
        display: block;
        padding: {
            top: vr(0.5);
            bottom: vr(0.5);
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }
    }

    &__icon {

    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: z(hero-background);

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: z(hero-background-overlay);
            background-color: currentColor;
        }

        background-color: $color-brand-white;

        &.-theme {

            &-default {
                background-color: $color-brand-white;
                color: $color-brand-blue-expert;
            }

            &-blueharmony {
                background-color: $color-brand-blue-harmony;
                color: $color-brand-blue-expert;
            }

            &-blueempower {
                background-color: $color-brand-blue-empower;
                color: $color-brand-white;
            }

            &-bluetrue {
                background-color: $color-brand-blue-true;
                color: $color-brand-white;
            }

            &-blueexpert {
                background-color: $color-brand-blue-expert;
                color: $color-brand-white;
            }

            &-yellowaction {
                background-color: $color-brand-yellow-action;
                color: $color-brand-blue-expert;
            }

            &-greylight {
                background-color: $color-brand-grey-light;
                color: $color-brand-blue-expert;
            }

            &-greycalm {
                background-color: $color-brand-grey-calm;
                color: $color-brand-white;
            }
        }
    }

    &__picture {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;
    }

    &__image {
        display: block;
        max-width: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
