.Button {
    $root: &;

    &.-theme {

        &-orange {
            border-color: $color-brand-warmorange;
            background-color: $color-brand-warmorange;
            color: $color-brand-white;

            //&:not(.is-loading) {
            //    color: $color-brand-warmorange;
            //}

            &:not([disabled]):not(.is-loading):not(#{$root}--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-warmorange-dark;
                    border-color: $color-brand-warmorange-dark;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-warmorange;
                }
            }
        }

        &-bluefull {
            border-color: $color-brand-pacificblue;
            background-color: $color-brand-pacificblue;
            color: $color-brand-white;

            //&:not(.is-loading) {
            //    color: $color-brand-warmorange;
            //}

            &:not([disabled]):not(.is-loading):not(#{$root}--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-pacificblue-light;
                    border-color: $color-brand-pacificblue-light;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-pacificblue;
                }
            }
        }

        &-green,
        &-article,
        &-story {
            border-color: $color-brand-palmgreen;

            &:not(.is-loading) {
                color: $color-brand-palmgreen;
            }

            &:not([disabled]):not(.is-loading):not(#{$root}--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-palmgreen;
                    color: $color-brand-white;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-palmgreen;
                }
            }
        }

        &-blue,
        &-event {
            border-color: $color-brand-pacificblue;

            &:not(.is-loading) {
                color: $color-brand-pacificblue;
            }

            &:not([disabled]):not(.is-loading):not(#{$root}--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-pacificblue;
                    color: $color-brand-white;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-pacificblue;
                }
            }
        }

        &-red,
        &-resourceset,
        &-document,
        &-video {
            border-color: $color-brand-heritagered;

            &:not(.is-loading) {
                color: $color-brand-heritagered;
            }

            &:not([disabled]):not(.is-loading):not(#{$root}--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-heritagered;
                    color: $color-brand-white;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-heritagered;
                }
            }
        }

        &-green-dark,
        &-greendark{
            border-color: $color-brand-green-dark;

            &:not(.is-loading) {
                color: $color-brand-green-dark;
            }

            &:not([disabled]):not(.is-loading):not(#{$root}--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-green-dark;
                    color: $color-brand-white;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-green-dark;
                }
            }
        }

        &-white {
            border-color: $color-brand-white;

            &:not(.is-loading) {
                color: $color-brand-white;
            }

            &:not([disabled]):not(.is-loading):not(#{$root}--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-white;
                    color: $color-brand-nero;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-white;
                }
            }
        }

        &-black {
            border-color: $color-brand-nero;

            &:not(.is-loading) {
                color: $color-brand-nero;
            }

            &:not([disabled]):not(.is-loading):not(#{$root}--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-nero;
                    color: $color-brand-white;
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-nero;
                }
            }
        }

        &-white-ghost {
            border-color: transparent;

            &:focus {
                outline-color: rgba($color-brand-white, 0.8);
            }

            &:not(.is-loading) {
                color: $color-brand-white;
            }

            &:not([disabled]):not(.is-loading):not(#{$root}--text) {

                &:hover,
                &:focus,
                &:active {
                    background-color: $color-brand-white;
                    color: $color-brand-nero;
                    border-color: $color-brand-white;
                }

                &:focus {
                    outline-color: rgba($color-brand-nero, 0.8);
                }
            }

            &.is-loading {
                background-color: transparent;

                &:after {
                    border-top-color: $color-brand-white;
                }
            }
        }

        &-black-ghost {
            border-color: transparent;
            background-color: transparent;

            &:focus {
                outline-color: rgba($color-brand-nero, 0.8);
            }

            &:not(.is-loading) {
                color: $color-brand-nero;
            }

            &:not([disabled]):not(.is-loading):not(#{$root}--text) {

                &:hover,
                &:focus,
                &:active {
                    //background-color: $color-brand-nero;
                    border-color: transparent;
                    background-color: transparent;
                    color: $color-brand-warmorange;
                    //border-color: $color-brand-nero;
                }

                &:focus {
                    outline-color: rgba($color-brand-warmorange, 0.8);
                }
            }

            //&.is-loading {
            //    background-color: transparent;
            //
            //    &:after {
            //        border-top-color: $color-brand-nero;
            //    }
            //}
        }
    }
}
