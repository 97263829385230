.SkipLinks {
    position: absolute;
    top: 0;
    left: rem(-32768);
    z-index: z(site-skiplinks);
    width: 100%;

    &__item {

    }

    &__link {
        position: absolute;
        top: rem(-32768);
        left: rem(-32768);

        &:focus,
        &:active {
            top: 0;
            left: rem(32768);
            outline: 0 none;
            width: 100%;
            color: $color-white;
            background: $color-charcoal;
            border-bottom: rem(1) solid $color-black;
            padding: vr(1);
            text-align: center;
            text-decoration: none;
        }
    }
}
