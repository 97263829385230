$color-border: $color-brand-lightgrey-dark;
$color-border-focus: $color-brand-darkgrey-pale;

// =========================
// Base Form Element Styles
// =========================

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

// FYI:
// The following fieldscurrently have poor x-browser support & inconsistent UI
// All fallback to input-textfield appearance when unsupported
// Consider pattern attr if necessary to use these to help reduce invalid data
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'],
input[type='week'],
// ^^^ end of poorly supported fields
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'] {
    appearance: none;
    border-radius: vr(0.5);
    border: {
        width: rem(1);
        style: solid;
        color: $color-border;
    }
    display: block;
    line-height: calc(#{vr(2)} - #{rem(2)});
    //height: vr(3.5);
    width: 100%;
    padding: {
        top: vr(0.75);
        bottom: vr(0.75);
        left: vr(1);
        right: vr(1);
    }

    &:not([disabled]),
    &:not([readonly]) {
        background-image: none;
        box-shadow: none;
    }

    /**
     * Readonly = not editable, can have focus, is sent on form submission
     * Disabled = not editable, not focusable, not sent on form submission
     */
    &[disabled],
    &[readonly] {
        background-image: none;
        box-shadow: none;
    }

    &[disabled] {
        cursor: not-allowed;
    }

    &:focus {
        outline: none;
        border-color: $color-border-focus;
    }
}

select:not([multiple]) {
    width: 100%;
    display: block;
    appearance: none;
    border-radius: vr(0.5);
    border: {
        width: rem(1);
        style: solid;
        color: $color-border;
    }
    line-height: calc(#{vr(2)} - #{rem(2)});
    //height: vr(3.5);
    padding: {
        top: vr(0.75);
        bottom: vr(0.75);
        left: vr(1);
        right: vr(3.5);
    }

    &:not([disabled]),
    &:not([readonly]) {
        box-shadow: none;
        background: $color-brand-white #{assetspath('svgs/forms/caret-down.svg')} no-repeat right #{vr(1)} center;
        background-size: vr(1.5);
    }

    &[disabled],
    &[readonly] {
        box-shadow: none;
        background: transparent #{assetspath('svgs/forms/caret-down.svg')} no-repeat right #{vr(1)} center;
        background-size: vr(1.5);
    }

    &[disabled] {
        cursor: not-allowed;
    }

    // https://stackoverflow.com/questions/19451183/cannot-remove-outline-dotted-border-from-firefox-select-drop-down
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
	// https://stackoverflow.com/questions/20163079/remove-select-arrow-on-ie
	&::-ms-expand {
		display: none;
	}

    &:focus {
        outline: none;
        border-color: $color-border-focus;
    }
}

select[multiple] {
    width: 100%;
    display: block;
    border-radius: vr(0.5);
    border: {
        width: rem(1);
        style: solid;
        color: $color-border;
    }
    background-image: none;
    box-shadow: none;

    option {
        padding: {
            top: vr(0.5);
            bottom: vr(0.5);
            left: vr(0.5);
            right: vr(0.5);
        }
    }
}

select::-ms-expand {
    visibility: hidden;
}

textarea {
    appearance: none;
    border-radius: vr(0.5);
    border: {
        width: rem(1);
        style: solid;
        color: $color-border;
    }
    display: block;
    width: 100%;
    padding: vr(1);

    // Make sure textarea takes on height automatically
    height: auto;
    min-height: vr(3.5);
    resize: vertical;

    &:not([disabled]),
    &:not([readonly]) {
        background-image: none;
        box-shadow: none;
    }

    /**
     * Readonly = not editable, can have focus, is sent on form submission
     * Disabled = not editable, not focusable, not sent on form submission
     */
    &[disabled],
    &[readonly] {
        background-image: none;
        box-shadow: none;
    }

    &[disabled] {
        cursor: not-allowed;
    }

    &:focus {
        outline: none;
        border-color: $color-border-focus;
    }
}

// https://medium.com/claritydesignsystem/pure-css-accessible-checkboxes-and-radios-buttons-54063e759bb3
input[type='checkbox'],
input[type='radio'] {

    // This styling expects a <label> to be the following adjacent sibling element
    // If the input is NOT the only-child of its parent AND the sibling is not <label>,
    // your input will not appear.
    &:not(:only-child) {
        opacity: 0;
        position:absolute;
        left: rem(-100000);
        top: auto;
        width: rem(1);
        height: rem(1);
        overflow: hidden;

        &:not([disabled]),
        &:not([readonly]) {

            + label {

                &:before {
                    color: inherit;
                }

                &:after {
                    color: inherit;
                }
            }

            &:checked + label:after {
                color: inherit;
            }
        }

        &:not([disabled]):not([readonly]) {

            &:not(:checked) {

                + label:hover:after {
                    opacity: 0.4;
                }

                &:focus  + label:after {
                    opacity: 0.4;
                }
            }
        }

        &[disabled],
        &[readonly] {

            + label {

                &:before {

                }
            }
        }

        &[disabled] {

            + label {
                cursor: not-allowed;
            }
        }

        + label {
            position: relative;
            display: block;
            padding-left: vr(2.75);
            cursor: pointer;

            &:before,
            &:after {
                position: absolute;
                content: '';
                transition: all 0.1s ease;
            }

            &:before {
                top: rem(2);
                left: rem(2);
                width: rem(20);
                height: rem(20);
                border: rem(1) solid currentColor;
            }

            &:after {
                top: rem(7);
                left: rem(7);
                width: rem(10);
                height: rem(10);
                background-color: $color-brand-green;
                transform: scale(.7);
                opacity: 0;
            }
        }

        &:checked + label:after {
            opacity: 1;
            transform: scale(1);
        }

        &:focus + label {

            &:before {
                outline: #{rem(1)} dotted currentColor;
                outline: -webkit-focus-ring-color auto rem(5);
            }
        }
    }
}

input[type='radio'] {

    &:not(:only-child) {

        + label {

            &:before,
            &:after {
                border-radius: 50%;
            }
        }
    }
}

input[type='hidden'] {
    display: none;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

input[type='range'],
input[type='color'],
input[type='file'] {
    display: block;
    width: 100%;
}
