// ==============================
// Contain & Normalise Embeds
// ==============================
embed,
object,
iframe,
video {
    display: block;
    max-width: 100%;
    border: 0;
}
