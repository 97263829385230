.ElementIconics {
    $root: &;

    &__list {
        display: grid;
        grid-gap: vr(5);
        justify-items: start;

        grid-template-columns: repeat(auto-fill, minmax(#{rem(275)}, 1fr));

        @supports (width: unquote('min(#{rem(275)}, 100%)')) {
            grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(275)}, 100%)'), 1fr));
        }

        &--feature {
            grid-template-columns: repeat(auto-fill, minmax(#{rem(400)}, 1fr));

            @supports (width: unquote('min(#{rem(400)}, 100%)')) {
                grid-template-columns: repeat(auto-fill, minmax(unquote('min(#{rem(400)}, 100%)'), 1fr));
            }
        }
    }

    &__iconic {
        width: 100%;
    }
}

.Iconic {
    $root: &;

    border-top: rem(2) solid $color-brand-green-dark;
    padding-top: vr(2);

    &.-align {

        &-center {
            text-align: center;
        }

        &-left {
            text-align: left;
        }

        &-right {
            text-align: right;
        }
    }

    &__hd {

    }

    &__icon {
        height: vr(3);
        margin-bottom: vr(0.75);

        &.-size {

            &-sm {
                height: vr(2.5);
                margin-bottom: vr(0.75);
            }

            &-md {
                height: vr(4);
                margin-bottom: vr(0.75);
            }

            &-md {
                height: vr(5.5);
                margin-bottom: vr(0.75);
            }
        }
    }

    &__bd {

    }

    &__title {
        font-family: $font-family-headings;
        font-weight: $font-weight-bold;
        font-size: rem(24);
        line-height: vr(2.5);
        margin-bottom: vr(1);
    }

    &__subtitle {
        color: $color-brand-grey;
        font-weight: $font-weight-light;
        font-size: rem(20);
        line-height: vr(2.25);
        margin: {
            top: vr(-0.25);
            bottom: vr(1);
        }
    }

    &__content {

    }

    &--feature {

        #{$root} {

            &__icon {
                height: vr(5.5);
                margin-bottom: 0;
            }

            &__hd {
                float: left;
            }

            &__bd {
                margin-left: vr(9);
            }
        }
    }
}
