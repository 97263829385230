.Sidebar {
    $root: &;

    max-width: rem(500);
    margin: 0 auto;

    &__block {
        margin: {
            top: vr(2);
            bottom: vr(2);
        }

        &:first-child {
            margin-top: vr(0.25);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
